import React from 'react'
import styled from 'styled-components'
import MarketCardButton from 'views/Button/MarketCardButton2'
import CivilianCardInfo from './CivilianInfo'

const CardStyled = styled.div`
  color: white;
  background: #263c77;

  padding: 20px;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1rem;
  border-radius: 12px;
`

const CardCivilian = ({ civilianInfo, status = 1 }) => {
  const { nftAddress, tokenId, accountAddress, paymentToken, price } = civilianInfo
  return (
    <CardStyled>
      <CivilianCardInfo civilianInfo={civilianInfo} />
      <MarketCardButton
        nftAddress={nftAddress}
        tokenId={tokenId}
        seller={accountAddress}
        paymentToken={paymentToken}
        status={status}
      />
    </CardStyled>
  )
}

export default CardCivilian
