
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import GlobalModal from 'components/static/GlobalModal'
import CongratulationModal from 'views/Modal/CongratulationModal'
import CivilianModal from 'views/Modal/CivilianModal'
import MysteryModal from '../../Modal/MysteryModal'
import BuyMystery from './components/BuyMystery'
import MysteryTitle from './components/Mystery'


function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function BuyChest(props) {
  const [active,setActive] = useState(false)
  const transactionTime = 2
  const numberArr = 3
  const milliSeconds = (transactionTime * 1000) + 4000 + (numberArr * 3000)
  const clickBuyChest = async () => {
    setActive(true)
    await sleep(transactionTime * 1000)
    GlobalModal.show(<MysteryModal numberArr={numberArr} animationDL={4.5}/>, { onPressBackground: null })
    await sleep(milliSeconds)
    GlobalModal.show(<CongratulationModal onClose={GlobalModal.hide} />)
    setActive(false)
  }

  return (
    <BuyChestStyled>
      <div className="bg__buy-chest">
        <video autoPlay muted playsInline loop style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
          <source src="/images/marketv2/spin.mp4" type="video/webm" />
          <source src="/images/marketv2/spin.org" type="video/org" />
        </video>
        <MysteryTitle className={active && "title-mystery"} transactionTime = {transactionTime - 1.2}/>
        <BuyMystery onClick={clickBuyChest} className={active && "buy-mystery"} transactionTime = {transactionTime - 1.2}/>
      </div>
    </BuyChestStyled>
  )
}

const BuyChestStyled = styled.div`
  .bg__buy-chest {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }

  .title-mystery{
    animation: ease-in-out animationTop 2s;
  }

  @keyframes animationTop {
    from {
      top: 73px;
    }
    to {
      top: -50%;
    }
  }

  .buy-mystery {
    animation: ease-in-out animationBottom 2s;
  }

  @keyframes animationBottom {
    from {
      bottom: 5%;
    }
    to {
      bottom: -50%;
    }
  }
`
export default BuyChest
