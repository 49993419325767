import { Col, Row, Skeleton } from 'antd'
import React from 'react'
import styled from 'styled-components'

const CardStyled = styled.div`
  color: white;
  background: #263c77;

  text-align: center;
  padding: 20px;
  line-height: 1.5rem;
  font-size: 1rem;
  border-radius: 12px;
  height: 459px;
  min-width: 240px;

  .ant-skeleton-element {
    display: block;
  }
`

const CardSkeleton = () => {
  return (
    <CardStyled>
      <Row justify="space-between">
        <Col span={8} style={{ textAlign: 'left' }}>
          <Skeleton.Button
            active
            style={{
              width: '70px',
              height: '20px',
              borderRadius: '4px',
            }}
          />

          <Skeleton.Button
            active
            size="large"
            style={{
              width: '90px',
              height: '20px',
              borderRadius: '4px',
              marginTop: '8px',
            }}
          />
        </Col>

        <Skeleton.Button
          active
          style={{
            width: '60px',
            height: '32px',
            borderRadius: '4px',
          }}
        />
      </Row>

      <Row justify="center">
        <Skeleton.Image
          style={{
            height: '160px',
            width: '140px',
            marginTop: '32px',
            borderRadius: '4px',
          }}
        />
      </Row>

      <Row justify="center">
        <Skeleton.Button
          active
          style={{
            display: 'block',
            marginTop: '24px',
            width: '100px',
            height: '28px',
            borderRadius: '4px',
          }}
        />
      </Row>

      <Skeleton.Button
        active
        style={{
          marginTop: '17px',
          width: '135px',
          height: '28px',
          borderRadius: '4px',
        }}
      />

      <Skeleton.Button
        active
        block
        style={{
          marginTop: '32px',
          height: '42px',
          borderRadius: '50px',
        }}
      />
    </CardStyled>
  )
}

export default CardSkeleton
