import React from 'react'
import CardCivilian from './components/CardItem'

// eslint-disable-next-line
const CiviliansListing = ({ listings, status = 1 }) => {
  return (
    <>
      {listings.map((civilian) => {
        return <CardCivilian civilianInfo={civilian} key={civilian._id} status={status} />
      })}
    </>
  )
}

export default CiviliansListing
