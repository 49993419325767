import React from 'react'
import { updateTxHash } from 'state/transactions/actions'
import styled from 'styled-components'

const TRANSACTION_STATUS = ['BOUGHT', 'SOLD', 'CANCEL', 'SELL', 'MINT']
//   BOUGHT: 0,
//   SOLD: 1,
//   CANCEL: 2,
//   SELL: 3,
//   MINT: 4,
// }

function HistoryDetail({ historyList }) {
  const historyFormatted = historyList.map((history) => {
    return {
      ...history,
      txHash: `${history.txHash?.slice(0, 5)}...${history?.txHash?.slice(-4)}`,
      accountAddress: `${history?.accountAddress?.slice(0, 5)}...${history?.accountAddress?.slice(-4)}`,
      date: new Date(history.unixTimestamp).toLocaleString(),
    }
  })

  return (
    <HistoryDetailStyled>
      <h3>Transaction history</h3>
      <div className="history__table">
        <table>
          <tr>
            <th>Address</th>
            <th>Hash</th>
            <th>Status</th>
            <th className="table__price">Details</th>
          </tr>
          {historyFormatted.map((history, index) => (
            <tr key={index}>
              <td>{history.accountAddress}</td>
              <td>{history.txHash}</td>
              <td className="status">{TRANSACTION_STATUS[history?.status]}</td>
              <td className="table__price">
                <p className="price">{history?.price} DPT</p>
                <p>{history?.date}</p>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </HistoryDetailStyled>
  )
}

const HistoryDetailStyled = styled.div`
  padding-top: 40px;
  @media only screen and (max-width: 600px) {
    padding-top: 20px;
  }
  h3 {
    color: #fff;
    font-size: 24px;
    font-weight: 800;
    padding-bottom: 32px;
  }
  .history__table {
    color: #fff;
    table {
      width: 100%;
      text-align: left;
      .table__price {
        text-align: right;
      }
      th {
        font-size: 18px;
        font-weight: 800;
        padding-bottom: 20px;
      }
      td {
        border-top: 1px solid #376bb9;
        text-transform: capitalize;
        padding: 20px 0px;
        @media only screen and (max-width: 600px) {
          font-size: 12px;
          padding-right: 5px;
        }
        @media only screen and (max-width: 375px) {
          font-size: 10px;
        }
      }
      td.table__price {
        p {
          font-weight: unset;
          font-size: 16px;
          @media only screen and (max-width: 600px) {
            font-size: 12px;
            padding-right: 3px;
          }
        }

        p.price {
          font-weight: 800;
          padding-bottom: 5px;
          @media only screen and (max-width: 600px) {
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
      .status {
        font-weight: 800;
        text-transform: uppercase;
        @media only screen and (max-width: 600px) {
          font-weight: 600;
        }
      }
    }
  }
`
export default HistoryDetail
