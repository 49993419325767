import React from 'react'
import styled from 'styled-components'
import BuyChest from './components/BuyChest'


function MysteryBox(props) {
  return (
    <MysteryBoxStyled>
      <BuyChest/>
    </MysteryBoxStyled>
  )
}

const MysteryBoxStyled = styled.div`
  text-align: center;
  h2 {
    font-size: 40px;
    font-weight: 800;
    color: #fff;
    text-shadow: 2px 2px #000;
    text-transform: uppercase;
    @media only screen and (max-width: 600px) {
      font-size: 25px;
    }
  }
`
export default MysteryBox
