import { createAction } from '@reduxjs/toolkit'

export const addTransaction = createAction<any>('transactions/addTransaction')

export const clearTransaction = createAction<any>('transactions/clearTransaction')

export const clearTransactionNotConfirmed = createAction<any>('transactions/clearTransactionNotConfirmed')

export const finalizeTransaction = createAction<any>('transactions/finalizeTransaction')

export const errorTransaction = createAction<any>('transactions/errorTransaction')

export const updateTxHash = createAction<any>('transactions/updateTxHash')
