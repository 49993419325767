import { ChainId } from '@diviner-protocol/v2-sdk'
import gameAbi from 'config/abi/game.json'
import cakeAbi from 'config/abi/cake.json'
import characterAbi from 'config/abi/character.json'
import chestMarketAbi from 'config/abi/chestMarket.json'
import marketplaceAbi from 'config/abi/DivinerMarketplace.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'

import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import faucetAbi from 'config/abi/faucet.json'
import gameWikiAbi from 'config/abi/gameWiki.json'
import ifoAbi from 'config/abi/ifo.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import multicall from 'config/abi/Multicall.json'
import prediction from 'config/abi/prediction.json'
import priceOracleAbi from 'config/abi/PriceOracle.json'
import { ethers } from 'ethers'
// Addresses
import {
  getCakeAddress,
  getCharacterAddress,
  getChestMarketAddress,
  getFakeCharacterAddress,
  getFaucetAddress,
  getGameAddress,
  getGameWikiAddress,
  getMarketplaceAddress,
  getMulticallAddress,
  getOracleAddress,
  getPredictionAddress,
} from 'utils/addressHelpers'
import { simpleRpcProvider } from 'utils/providers'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}

export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoAbi, address, signer)
}

export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(cakeAbi, getCakeAddress(chainId), signer)
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(multicall, getMulticallAddress(), signer)
}

export const getPredictionContracts = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getPredictionAddress().map((address) => getContract(prediction, address, signer))
  // return getContract(prediction, getPredictionAddress(), signer)
}

// Marketplace
export const getMarketplaceContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  if (getMarketplaceAddress(chainId) === '') return null
  return getContract(marketplaceAbi, getMarketplaceAddress(chainId), signer)
}

// Charaters
export const getCharacterContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  if (getCharacterAddress(chainId) === '') return null
  return getContract(characterAbi, getCharacterAddress(chainId), signer)
}
export const getFakeCharacterContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  if (getFakeCharacterAddress(chainId) === '') return null
  return getContract(fakeCharacterAbi, getFakeCharacterAddress(chainId), signer)
}

// Chest market
export const getChestMarketContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(chestMarketAbi, getChestMarketAddress(chainId), signer)
}

// faucet
export const getFaucetContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(faucetAbi, getFaucetAddress(chainId), signer)
}

// game
export const getGameContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(gameAbi, getGameAddress(chainId), signer)
}

export const getGameWikiContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(gameWikiAbi, getGameWikiAddress(chainId), signer)
}

export const getPriceOracleContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(priceOracleAbi, getOracleAddress(chainId), signer)
}
