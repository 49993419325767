import { LoadingOutlined } from '@ant-design/icons'
import useMarketActionButton from 'hooks/useMarketActionButton'
import { MarketCardButtonStyled } from './MarketCardButtonStyled'

const MarketCardButton = ({ nftAddress, tokenId, seller, paymentToken, status }) => {
  const { titleButton, action, isCancel, isLoading } = useMarketActionButton({
    nftAddress,
    tokenId,
    seller,
    status,
    paymentToken,
    owner: seller,
  })

  return (
    <>
      <MarketCardButtonStyled isCancel={isCancel} isLoading={isLoading} disabled={isLoading} onClick={action}>
        {isLoading ? <LoadingOutlined style={{ fontSize: 19 }} spin /> : titleButton}
      </MarketCardButtonStyled>
    </>
  )
}

export default MarketCardButton
