import { useWeb3React } from '@web3-react/core'
import { InputNumber, Select } from 'antd'
import { useAddListing } from 'hooks/marketplace'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { paymentTokens } from 'config/constants/marketplace'
import { State } from 'state/types'
import { addCommas } from 'utils/formatNumber'
import { getDptAddress } from 'utils/addressHelpers'

const { Option } = Select
const SelectAfter = ({ setPaymentTokenAddress, chainId }) => {
  const onChangePaymentToken = (value) => {
    setPaymentTokenAddress(value)
  }
  return (
    <Select defaultValue={paymentTokens[0].address[chainId]} onChange={onChangePaymentToken}>
      {paymentTokens.map((token) => (
        <Option value={token.address[chainId]} key={token.name}>
          {token.name}
        </Option>
      ))}
    </Select>
  )
}

function SellModal({ onClose, nftAddress, tokenId }) {
  const [price, setPrice] = useState()
  const { chainId } = useWeb3React()
  const [paymentTokenAddress, setPaymentTokenAddress] = useState(paymentTokens[0].address[chainId])
  const [onAddListing] = useAddListing(tokenId, nftAddress)
  const sellerFee = useSelector((state: State) => state.marketplace.fee.buyerFee)
  const [isWarning, setIsWarning] = useState(false)

  const onChangePrice = (value) => {
    if (parseFloat(value) > 0 && !Number.isNaN(parseFloat(value))) {
      setIsWarning(false)
    } else {
      setIsWarning(true)
    }
    setPrice(value)
  }

  const handleAddListing = () => {
    if (!isWarning) {
      onAddListing(price, paymentTokenAddress)
      onClose()
    }
  }

  return (
    <SellModalStyled>
      <h3>Sell NFT</h3>
      <div className="sell__content">
        <p>Set price</p>
        <p>Tax: {paymentTokenAddress === getDptAddress() ? 0 : 5}%</p>
      </div>
      <div className="sell__input-number">
        <InputNumber
          addonAfter={SelectAfter({ setPaymentTokenAddress, chainId })}
          decimalSeparator="."
          value={price}
          autoFocus
          controls={false}
          formatter={(value) => addCommas(value)}
          onChange={onChangePrice}
        />
        {isWarning && <p className="please__input">Please input valid price </p>}
      </div>

      <div className="button__sell">
        <div className={`button sell ${(isWarning || !price) && 'disable'}`} onClick={handleAddListing}>
          Sell
        </div>
        <div className="button cancel" onClick={onClose}>
          <p>Cancel</p>
        </div>
      </div>
    </SellModalStyled>
  )
}

const SellModalStyled = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  h3 {
    font-weight: 800;
    font-size: 24px;
    color: #55b3f8;
    margin-bottom: 40px;
    text-align: center;
  }
  .sell__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 16px;
      color: #000;
    }
  }
  .button__sell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button {
      border-radius: 50px;
      background: #fff;
      border: solid 1px #000;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 197px;
      height: 42px;
      cursor: pointer;
      @media (max-width: 414px) {
        width: 130px;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin: 0;
      }
    }
    .button.sell {
      font-size: 1rem;
      background: #55b3f8;
      margin-right: 12px;
      border: unset;
      p {
        color: #fff;
      }
    }

    .button.disable {
      opacity: 0.5;
    }
  }
  .sell__input-number {
    margin-bottom: 28px;
    .ant-input-number-input-wrap {
      display: flex;
      justify-content: center;

      align-items: center;
    }

    .ant-input-number-group-wrapper {
      width: 100%;
      .ant-input-number {
        border: unset;
        background: #e9e9e9;
        color: #a5a5a5;
        font-size: 14px;
        font-weight: 400;
        height: 42px;
        display: flex;
        align-items: center;
      }
      .ant-input-number-group-addon {
        border: unset;
        background: #e9e9e9;
        color: #a5a5a5;
        font-size: 14px;
        height: 42px;
        font-weight: 400;
      }
    }
    span {
      color: #000;
    }
    p.please__input {
      /* display: none; */
      font-weight: 400;
      font-size: 16px;
      color: #ff1515;
      padding-top: 8px;
    }
  }
`
export default SellModal
