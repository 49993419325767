import { ROUTER_ADDRESSES, WNATIVE } from '@diviner-protocol/v2-sdk'
import { Contract } from '@ethersproject/contracts'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import SMART_CHEF_ABI from 'config/abi/smartChef.json'
import { useActiveWeb3React } from 'hooks'
import { useMemo } from 'react'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import ROUTER_ABI from '../constants/abis/router.json'
import WETH_ABI from '../constants/abis/weth.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract } from '../utils'
import {
  getErc721Contract,
  getBep20Contract,
  getCakeContract,
  getCharacterContract,
  getChestMarketContract,
  getFakeCharacterContract,
  getFaucetContract,
  getGameContract,
  getIfoContract,
  getMarketplaceContract,
  getPredictionContracts,
} from '../utils/contractHelpers'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case 56:
      case 97:
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useRouterContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(ROUTER_ADDRESSES[chainId], ROUTER_ABI, withSignerIfPossible)
}

export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()
  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export const useIfoContract = (address: string) => {
  const { library } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getIfoContract(address, signer), [address, signer])
}

export const useERC20 = (address: string) => {
  const { library } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getBep20Contract(address, signer), [address, signer])
}

export const useERC721 = (address: string) => {
  const { library } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getErc721Contract(address, signer), [address, signer])
}

export const usePredictionContracts = () => {
  const { library, chainId } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getPredictionContracts(signer, chainId), [signer, chainId])
}

export const useCake = () => {
  const { library, chainId } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getCakeContract(signer, chainId), [signer, chainId])
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}
export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WNATIVE[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}
export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useStakingContract(address: any | null): Contract | null {
  return useContract(address, SMART_CHEF_ABI, true)
}

export const useMarketplaceContract = (): Contract | null => {
  const { library, chainId } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getMarketplaceContract(signer, chainId), [signer, chainId])
}

export const useCharacterContract = (): Contract | null => {
  const { library, chainId } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getCharacterContract(signer, chainId), [signer, chainId])
}

export const useFakeCharacterContract = (): Contract | null => {
  const { library, chainId } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getFakeCharacterContract(signer, chainId), [signer, chainId])
}

export const useChestMarketContract = () => {
  const { library, chainId } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getChestMarketContract(signer, chainId), [signer, chainId])
}

export const useGameContract = () => {
  const { library, chainId } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getGameContract(signer, chainId), [signer, chainId])
}

export const useFaucetContract = () => {
  const { library, chainId } = useActiveWeb3React()
  const signer = library.getSigner()
  return useMemo(() => getFaucetContract(signer, chainId), [signer, chainId])
}
