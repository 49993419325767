import { useWeb3React } from '@web3-react/core'
import GlobalModal from 'components/static/GlobalModal'
import { NFT_TYPE, paymentTokens } from 'config/constants/marketplace'
import React from 'react'
import { useSelector } from 'react-redux'
// eslint-disable-next-line
import { AppState } from 'state'
import {
  fetchIsApprovedForAll,
  fetchUserDptToken,
  marketplaceFetchingSelector,
} from 'state/marketplace/selectors/selector'
import { getBusdAddress, getCharacterAddress, getDptAddress, getMarketplaceAddress } from 'utils/addressHelpers'
import ConnectWalletModal from 'views/Modal/ConnectWalletModal'
import SellModal from 'views/Modal/SellModal'
// import SellModal from 'views/Modal/SellModal'
import { useCancelListing, usePurchaseListing } from './marketplace'
import { useApproveCallbackCustom } from './useApprove'
import { useApproveForAll } from './useApproveNft'

const useMarketActionButton = ({
  nftAddress = getCharacterAddress(),
  tokenId,
  seller,
  owner = '',
  status,
  listingId = '',
  paymentToken = '',
  price = 0,
}) => {
  const { account, chainId } = useWeb3React()
  const { busd, dpt } = useSelector((state: AppState) => state.user.userTokenData)

  const fetching = useSelector(marketplaceFetchingSelector)
  const isApprovedForAll = useSelector(fetchIsApprovedForAll)

  const [onCancelListing] = useCancelListing(nftAddress, tokenId)
  const [onPurchaseListing] = usePurchaseListing(nftAddress, tokenId)
  const [onApproveNFT] = useApproveForAll(nftAddress, getMarketplaceAddress())

  console.log('nftAddress', nftAddress)
  const [onApproveBUSD] = useApproveCallbackCustom(getBusdAddress(), getMarketplaceAddress())

  const [onApproveDPT] = useApproveCallbackCustom(getDptAddress(), getMarketplaceAddress())

  let titleButton = null
  let action = null
  let isCancel = false
  const isLoading = false

  if (!account) {
    titleButton = 'Connect Wallet'
    action = () => {
      GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
    }
  } else if (status) {
    if (seller === account) {
      titleButton = 'Cancel'
      isCancel = true
      action = onCancelListing
    } else if (paymentToken === getBusdAddress() && !busd.isAllowance) {
      titleButton = 'Approve BUSD'
      action = onApproveBUSD
    } else if (paymentToken === getDptAddress() && !dpt.isAllowance) {
      titleButton = 'Approve DPT'
      action = onApproveDPT
    } else {
      titleButton = 'Buy'
      action = onPurchaseListing
    }
  } else if (!isApprovedForAll) {
    titleButton = 'Approve NFT'
    action = onApproveNFT
  } else if (owner === account) {
    titleButton = 'Sell'
    action = () => GlobalModal.show(<SellModal onClose={GlobalModal.hide} nftAddress={nftAddress} tokenId={tokenId} />)
  } else {
    titleButton = null
  }

  return {
    titleButton,
    action,
    isCancel,
    isLoading: fetching.button,
  }
}

export default useMarketActionButton
