import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils'
import { getCallData } from 'utils/calldata'
import { useTokenContract } from './useContract'
import { useAppDispatch } from '../state/index'
import { finalizeTransaction, errorTransaction } from '../state/transactions/actions'
import { setUserState } from 'state/user/reducer'
import { setMarketplaceState } from 'state/marketplace'
import { marketplaceFetchingSelector } from 'state/marketplace/selectors/selector'
import { useSelector } from 'react-redux'

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallbackCustom(token?: any, addressNeedApprove?: string) {
  const tokenContract = useTokenContract(token)
  const addTransaction = useTransactionAdder()
  const dispatch = useAppDispatch()

  const fetching = useSelector(marketplaceFetchingSelector)

  const approve = useCallback(async () => {
    const callData = getCallData.approve({ tokenAddress: token, addressNeedApprove })
    addTransaction({ callData })

    const estimatedGas = await tokenContract.estimateGas.approve(addressNeedApprove, MaxUint256).catch(() => {
      return tokenContract.estimateGas.approve(addressNeedApprove, MaxUint256)
    })

    try {
      dispatch(
        setMarketplaceState({
          fetching: {
            ...fetching,
            button: true,
          },
        }),
      )
      const tx = await tokenContract.approve(addressNeedApprove, MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })

      await tx.wait()

      // dispatch(finalizeTransaction({ callData, message: 'Approve DPT successfully' }))
      dispatch(setUserState({ rebound: true }))
    } catch (error) {
      console.debug('Failed to approve token', error)
      dispatch(errorTransaction({ callData, message: 'Approve DPT fail' }))
      throw error
    } finally {
      dispatch(
        setMarketplaceState({
          fetching: {
            ...fetching,
            button: false,
          },
        }),
      )
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [tokenContract, addressNeedApprove])

  return [approve]
}
