import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'
import CivilianModal from './CivilianModal'

function CongratulationModal({ onClose }) {
  return (
    <CongratulationStyled>
      <div className="congratulation">
        <div className="congratulation__title">
          <img src="./images/modal-market/congratulation.svg" alt="congratulation.svg" />
          <p>Congratulation</p>
        </div>
        <div className="congratulation__content">
          {[...Array(10)].map(() => (
            <CivilianModal />
          ))}
        </div>
        <div className="congratulation__button">
          <Button className="button__close" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </CongratulationStyled>
  )
}

const CongratulationStyled = styled.div`
  position: relative;
  border-radius: 12px;
  text-align: center;
  border: solid 16px #79befe;
  background: #79befe;

  @media only screen and (max-width: 600px) {
    padding: 20px 10px 10px 10px;
    border: solid 5px #79befe;
  }

  .congratulation {
    padding: 110px 60px 40px 60px;
    border-radius: 12px;
    background: #fff;
    height: 550px;
    overflow-x: scroll;
    @media only screen and (max-width: 600px) {
      padding: 20px 10px;
      height: 250px;
    }
    .congratulation__title {
      position: absolute;
      z-index: 100;
      top: -8px;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: #fff;
        font-size: 2.25rem;
        font-weight: 800;
        margin: 0;
        text-transform: uppercase;
        position: absolute;
        @media only screen and (max-width: 600px) {
          font-size: 1.3rem;
        }
      }
      img {
        width: 37.6rem;
        @media only screen and (max-width: 600px) {
          width: 22rem;
        }
      }
    }
    .congratulation__content {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 1em;

      @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media only screen and (max-width: 500px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .congratulation__button {
      display: flex;
      justify-content: center;
      align-items: center;
      .button__close {
        cursor: pointer;
        background: #55b3f8;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
        border-radius: 50px;
        border: unset;
        width: 200px;
        height: 52px;
        margin-top: 40px;
        text-transform: uppercase;
        @media only screen and (max-width: 600px) {
          margin-top: 20px;
        }
      }
    }
  }
`
export default CongratulationModal
