import { Slider } from 'antd'
import { filter, isEmpty } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { filtersSelector } from 'state/marketplace/selectors/selector'
import styled from 'styled-components'
const marks = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
}

function RarityFilter({ handleChangeFilter }) {
  const [heroRating, setHeroRating] = useState<[number, number]>([1, 5])
  const filters = useSelector(filtersSelector)
  useEffect(() => {
    if (isEmpty(filters)) setHeroRating([1, 5])
  }, [filters])
  const handleChange = (value) => {
    handleChangeFilter({
      filters: {
        ...filters,
        heroRating: `${value?.[0] - 1},${value?.[1] - 1}`,
      },
    })
    setHeroRating(value)
  }
  return (
    <LevelFilterStyled>
      <Slider onChange={handleChange} range marks={marks} defaultValue={[1, 5]} min={1} max={5} value={heroRating} />
    </LevelFilterStyled>
  )
}
const LevelFilterStyled = styled.div`
  .ant-slider.ant-slider-with-marks {
    &:hover .ant-slider-track {
      background: #3786ff;
    }
  }
  .ant-slider-handle {
    width: 18px;
    height: 18px;
    background: #3786ff;
    border: unset;
    margin-left: 5px;
  }

  .ant-slider-rail {
    height: 8px;
  }

  .ant-slider-track {
    height: 8px;
    background: #3786ff;
  }

  /* .ant-slider-dot.ant-slider-dot-active {
    background: #3786ff;
  } */

  .ant-slider-dot {
    /* width: 18px;
    height: 18px;
    border: unset;
    background: #fff;
    top: -4px;
    margin-left: 4px;
    transform: translate(0px, -50%); */
    display: none;
  }

  .ant-slider-mark-text-active {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 5px;
  }

  span.ant-slider-mark-text {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 5px;
  }
`

const RarityFilterStyled = styled.div`
  padding-bottom: 20px;
  .ant-slider.ant-slider-with-marks {
    &:hover .ant-slider-track {
      background: #3786ff;
    }
  }
  .ant-slider-handle {
    width: 18px;
    height: 18px;
    background: #3786ff;
    border: unset;
    margin-left: 5px;
  }

  .ant-slider-rail {
    height: 8px;
  }

  .ant-slider-track {
    height: 8px;
    background: #3786ff;
  }

  .ant-slider-dot.ant-slider-dot-active {
    background: #3786ff;
  }

  .ant-slider-dot {
    width: 18px;
    height: 18px;
    border: unset;
    background: #fff;
    top: 4px;
    transform: translate(0px, -50%);
  }

  .ant-slider-mark-text-active {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 5px;
  }

  span.ant-slider-mark-text {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 5px;
  }
`
export default RarityFilter
