import { Checkbox } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { filtersSelector } from 'state/marketplace/selectors/selector'
import styled from 'styled-components'

function RatingsFilter({ handleChangeFilter }) {
  const filters = useSelector(filtersSelector)
  const handleClickCheckbox = (index) => () => {
    let updatedFilters = { ...filters }
    const heroIndex =
      (filters?.heroRarity && filters?.heroRarity?.findIndex((heroRarity) => heroRarity === index)) ?? -1

    if (heroIndex > -1) {
      updatedFilters.heroRarity = updatedFilters.heroRarity.filter((heroRarity, idx) => idx !== heroIndex)
    } else {
      updatedFilters = {
        ...filters,
        heroRarity: [...(filters.heroRarity || []), index],
      }
    }
    handleChangeFilter({
      filters: updatedFilters,
    })
  }
  const Content = new Array(5).fill(0).map((_, idx) => (
    <div className="ratings__checkbox" key={`ratings-${idx}`}>
      <Checkbox
        checked={filters.heroRarity && filters.heroRarity.some((item) => item === idx)}
        onClick={handleClickCheckbox(idx)}
      >
        {new Array(idx + 1).fill(0).map((__, subIdx) => (
          <img key={`star-${idx}-${subIdx}`} src="./images/market/star.svg" alt="star" />
        ))}
      </Checkbox>
    </div>
  ))
  return <RatingsFilterStyled>{Content}</RatingsFilterStyled>
}

const RatingsFilterStyled = styled.div`
  .ratings__checkbox {
    padding-bottom: 20px;
    span.ant-checkbox.ant-checkbox-checked {
      &::after {
        opacity: 0;
        border: unset;
      }
    }
    img {
      padding-right: 4px;
    }
  }

  .ratings__checkbox.bottom {
    padding-bottom: 0px;
  }
`
export default RatingsFilter
