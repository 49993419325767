import { LoadingOutlined } from '@ant-design/icons'
import useMarketActionButton from 'hooks/useMarketActionButton'
import { isEmpty } from 'lodash'
import styled, { css } from 'styled-components'

const PageDetailButtonStyled = styled.div<{ isLoading: boolean; isCancel: boolean }>`
  background: ${(props) => (props.isCancel ? '#DE4444' : '#55b3f8')};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 40px;
  padding: 16px 0px;
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: white;
  ${(props) => {
    if (!props.isLoading) {
      return css`
        &:hover {
          background: ${props.isCancel ? '#B91919' : '#1487db'};
          cursor: pointer;
        }
      `
    }
  }};
`

const PageDetailButton = ({ nftAddress, tokenId, seller, owner, paymentToken, price, status }) => {
  const { titleButton, action, isCancel, isLoading } = useMarketActionButton({
    nftAddress,
    tokenId,
    seller,
    status,
    paymentToken,
    price,
    owner,
  })

  return (
    <>
      {!isEmpty(titleButton) && (
        <PageDetailButtonStyled isCancel={isCancel} isLoading={isLoading} onClick={!isLoading && action}>
          {isLoading ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : titleButton}
        </PageDetailButtonStyled>
      )}
    </>
  )
}

export default PageDetailButton
