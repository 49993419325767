import { MaxUint256 } from '@ethersproject/constants'
import GlobalModal from 'components/static/GlobalModal'

import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin } from 'utils'
import { getCallData } from 'utils/calldata'
import ConnectWalletModal from 'views/Modal/ConnectWalletModal'
import TransactionModal from 'views/Modal/TransactionModal'
import { useAppDispatch } from '../state/index'
import { errorTransaction, finalizeTransaction } from '../state/transactions/actions'
import { useERC721 } from './useContract'
import { updateIsApprovedForAll } from '../state/marketplace/actions/actions'
import { setMarketplaceState } from 'state/marketplace'
import { useSelector } from 'react-redux'
import { marketplaceFetchingSelector } from 'state/marketplace/selectors/selector'

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveForAll(nftAddress?: string, addressNeedApprove?: string) {
  const nftContract = useERC721(nftAddress)
  const fetching = useSelector(marketplaceFetchingSelector)

  const addTransaction = useTransactionAdder()
  const dispatch = useAppDispatch()

  const onApproveForAll = useCallback(async () => {
    dispatch(
      setMarketplaceState({
        fetching: {
          ...fetching,
          button: true,
        },
      }),
    )
    const estimatedGas = await nftContract.estimateGas.setApprovalForAll(addressNeedApprove, true).catch(() => {
      return nftContract.estimateGas.setApprovalForAll(addressNeedApprove, true)
    })

    try {
      const tx = await nftContract.setApprovalForAll(addressNeedApprove, MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      dispatch(
        setMarketplaceState({
          isLoading: true,
        }),
      )
      const response = await tx.wait()

      // eslint-disable-next-line
      const updatedState = { isApprovedForAll: true }
      dispatch(setMarketplaceState(updatedState))
    } catch (error) {
      dispatch(
        setMarketplaceState({
          isLoading: false,
          fetching: {
            ...fetching,
            button: false,
          },
        }),
      )
      console.log('err approve nft', error)
    } finally {
      dispatch(
        setMarketplaceState({
          isLoading: false,
          fetching: {
            ...fetching,
            button: false,
          },
        }),
      )
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [nftContract, addressNeedApprove])

  return [onApproveForAll]
}
