import { useWeb3React } from '@web3-react/core'
import { Col, Row } from 'antd'
import { getPaymentToken } from 'config/constants/marketplace'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { addCommas } from 'utils/formatNumber'
import PageDetailButton from 'views/Button/PageDetailButton'
import HistoryDetail from './components/HistoryDetail'

function Detail({ civilian }) {
  const {
    price,
    name,
    level,
    heroRarity,
    heroRating,
    attack,
    defense,
    accountAddress,
    detailImg,
    tokenId,
    nftAddress,
    status,
    history,
    paymentToken,
  } = civilian

  const { account } = useWeb3React()
  const owner = accountAddress ?? account
  const trimAccount = owner ? `${owner.slice(0, 4)}...${owner.slice(38)}` : '0x00...0000' // account length: 42
  const lucky = (parseInt(attack) + parseInt(defense)) / 2
  const priceDisplayed = price ? addCommas(price) : ''

  return (
    <DetailStyled>
      <Link to="/">
        <div className="button__back">
          <img src="./images/modal-market/chevron-left.svg" alt="chevron-left" />
          <span>Back</span>
        </div>
      </Link>

      <Row gutter={[40, 40]}>
        <Col xs={24} md={24} lg={12} xl={12}>
          <img src={detailImg} alt="civilian" className="civilian__img" />
        </Col>
        <Col xs={24} md={24} lg={12} xl={12}>
          <div className="detail__name-id">
            <p className="detail__name">{name}</p>
            <p className="detail__id">#{tokenId}</p>
          </div>
          <div className="detail__price">
            {priceDisplayed && (
              <div className="detail__price--dpt">
                <img src={`/images/tokens/${getPaymentToken(paymentToken).img}`} alt="price dpt" />
                <span>
                  {priceDisplayed} {getPaymentToken(paymentToken).symbol}
                </span>
              </div>
            )}
            {/* <div className="detail__price--usd">
              <span>~ 0.402 USD</span>
            </div> */}
          </div>
          <div className="detail__parameter">
            <p>
              Owner: <span>{trimAccount}</span>
            </p>
          </div>
          <div className="detail__parameter">
            <p>
              Level: <span>{level}</span>
            </p>
          </div>
          <div className="detail__parameter">
            <p>
              Ratings: <span>{heroRating + 1}</span>
            </p>
          </div>
          <div className="detail__parameter">
            <p>Rarity: </p>
            <div className="rating_star">
              {[...Array(heroRarity + 1)].map(() => (
                <img src="/images/market/star.svg" alt="star" />
              ))}
            </div>
          </div>
          <div className="detail__parameter">
            <p>Stats</p>
          </div>
          <div className="detail__stars">
            <div className="detail__stars__content">
              <div className="detail__stars__content--name">
                <p>Strength</p>
                <div className="detail__stars__content--icon">
                  <img src="./images/market/power.svg" alt="Strength" />
                  <p>{attack}</p>
                </div>
              </div>
            </div>
            <div className="detail__stars__content lucky">
              <div className="detail__stars__content--name">
                <p>Luck</p>
                <div className="detail__stars__content--icon">
                  <img src="./images/market/lucky.svg" alt="lucky" />
                  <p>{lucky.toFixed(0)}</p>
                </div>
              </div>
            </div>
            <div className="detail__stars__content">
              <div className="detail__stars__content--name">
                <p>Attractive</p>
                <div className="detail__stars__content--icon">
                  <img src="./images/market/blood.svg" alt="Attractive" />
                  <p>{defense}</p>
                </div>
              </div>
            </div>
          </div>
          <PageDetailButton
            nftAddress={nftAddress}
            tokenId={tokenId}
            seller={accountAddress}
            owner={accountAddress}
            paymentToken={paymentToken}
            status={status}
            price={price}
          />
        </Col>
      </Row>

      <HistoryDetail historyList={history} />
    </DetailStyled>
  )
}

const DetailStyled = styled.div`
  padding: 0px 15rem;
  background: #15244e;
  .civilian__img {
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    padding: 0px 5rem;
  }
  @media only screen and (max-width: 600px) {
    padding: 0px 1rem;
  }
  p {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }
  span {
    color: #fff;
  }
  .button__back {
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: 800;
    color: #fff;
    padding-bottom: 65px;
    @media only screen and (max-width: 1024px) {
      padding-bottom: 40px;
    }
    @media only screen and (max-width: 600px) {
      padding-bottom: 25px;
    }
  }

  .detail__content {
    .detail__name-id {
      border-bottom: 1px solid #376bb9;
    }
  }

  .detail__name-id {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #376bb9;
    padding-bottom: 20px;
    .detail__id {
      background: rgba(75, 138, 183, 0.3);
      border-radius: 4px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      @media only screen and (max-width: 600px) {
        font-size: 18px;
      }
    }
    .detail__name {
      font-size: 40px;
      font-weight: 800;
      @media only screen and (max-width: 1024px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 600px) {
        font-size: 20px;
      }
    }
  }

  .detail__price {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 40px 0px;
    @media only screen and (max-width: 1024px) {
      padding: 20px 0px;
    }
    .detail__price--dpt {
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: 28px;
      font-weight: 800;
      padding-right: 15px;
      @media only screen and (max-width: 600px) {
        font-size: 18px;
      }
      img {
        margin-right: 10px;
        width: 40px;
        height: 40px;
      }
    }
    .detail__price--usd {
      span {
        opacity: 0.8;
        font-size: 18px;
        @media only screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
    }
  }

  .detail__parameter {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-bottom: 20px;
    p {
      color: #94bfff;
      font-size: 20px;
      font-weight: 800;
      @media only screen and (max-width: 600px) {
        font-size: 18px;
      }
      span {
        font-size: 18px;
        font-weight: 200;
        @media only screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
    }
    .rating_star {
      padding-left: 10px;
      img {
        padding-right: 5px;
      }
    }
  }

  .detail__stars {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .detail__stars__content {
      max-width: 206px;
      width: 100%;
      height: 92px;
      background: #15295f;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      p {
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 15px;
        color: #94bfff;
      }
      .detail__stars__content--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          padding-bottom: 0px;
          color: #fff;
        }
        img {
          padding-right: 5px;
        }
      }
    }
    .detail__stars__content.lucky {
      margin: 0px 40px;
      @media only screen and (max-width: 1024px) {
        margin: 0px 20px;
      }
      @media only screen and (max-width: 600px) {
        margin: 0px 10px;
      }
    }
  }
`
export default Detail
