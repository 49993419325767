import React from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

const LoadingFullScreen = (props) => {
  return (
    <LoadingMain className="loader-container" {...props}>
      <div className="loading__style">
        <div className="loading__black">
          {/* <img src="/images/icon-loading.png" alt="" /> */}

          {/* <div className="loading-animate">
          <span>l</span>
          <span>o</span>
          <span>a</span>
          <span>d</span>
          <span>i</span>
          <span>n</span>
          <span>g</span>
        </div> */}
        </div>
        <div className="loading__after"></div>
      </div>
    </LoadingMain>
  )
}

const LoadingMain = styled.div`
  position: relative;
  .loading__style {
    width: 100vw;
    height: 100vh;
    background: url('./images/loading/img-loading.jpg') no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;

    .loading__black {
      position: absolute;
      top: 0%;
      height: 3vh;
      width: 100vw;
      background: #000;
      z-index: 10;
      transform: unset;
    }
    .loading__after {
      position: absolute;
      top: 0%;
      height: 3vh;
      width: 95vw;
      background: #09a2ff;
      z-index: 100;
      animation: runLoading 5s ease;
    }
    @keyframes runLoading {
      from {
        width: 0vw;
      }
      to {
        width: 95vw;
      }
    }
    /* .loading-animate {
    font-family: 'Oxanium', cursive;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      width: 550px;
      margin: 0 auto;
    }
    @media only screen and (max-width: 600px) {
      font-size: 1rem;
      width: 450px;
    }
  } */
  }
`

export default LoadingFullScreen
