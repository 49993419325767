import { Pagination } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state/index'
import { updateMarketPage } from 'state/marketplace/actions/actions'
import { fetchListingCivilians } from 'state/marketplace/actions/marketActions'
import { paginationSelector } from 'state/marketplace/selectors/selector'
import { State } from 'state/types'
import styled from 'styled-components'

function PaginationMarket({ changePageHandler, limit, marketPage, totalResults }) {
  // const { limit, marketPage, totalResults } = useSelector(paginationSelector)
  const { marketplace: listingAmount } = useSelector((state: State) => state.marketplace.amount)
  const dispatch = useAppDispatch()

  // const changePageHandler = (page) => {
  //   dispatch(updateMarketPage(page))
  //   // dispatch(fetchListingCivilians())
  // }

  return (
    !!totalResults && (
      <PaginationMarketStyled>
        <Pagination
          defaultCurrent={marketPage}
          current={marketPage}
          pageSize={limit}
          total={totalResults}
          onChange={changePageHandler}
        />
      </PaginationMarketStyled>
    )
  )
}

const PaginationMarketStyled = styled.div`
  padding: 40px 0px;
  .ant-pagination {
    display: flex;
    justify-content: center;

    .ant-pagination-options {
      display: none;
    }

    .ant-pagination-item-ellipsis {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    li {
      background: #3d4764;
      width: 42px;
      height: 42px;
      border-radius: 4px;
      border: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      transition: ease all 0.5s;
      &:hover {
        background: #3786ff;
      }

      a {
        color: #fff;
        font-weight: 400;
        font-size: 18px;
      }
    }

    li.ant-pagination-item-active {
      background: #3786ff;
    }

    .ant-pagination-item-link {
      background: #3d4764;
      border: unset;
      .anticon {
        color: #fff;
      }
    }
  }
`
export default PaginationMarket
