import { isFulfilledAction, isPendingAction, isRejectedAction } from 'utils/helpers'
import { createSlice } from '@reduxjs/toolkit'
import { IHero } from 'state/marketplace/types/types'
import gameCharacter from 'config/constants/game'

export interface IHistoryDetails {
  status: number
  accountAddress: string
  id: string
  hero: IHero
}
export interface IHistoryListing {
  results: IHistoryDetails[]
  limit: number
  totalPages: number
  totalResults: number
  page: number
}
export interface HistoryState {
  isLoading: boolean
  listing: IHistoryListing
}

export const initialState: HistoryState = {
  isLoading: false,
  listing: {
    limit: 0,
    page: 0,
    results: [],
    totalPages: 0,
    totalResults: 0,
  },
}
export const HISTORY_SLICE_NAME = 'history'
export const historySlicer = createSlice({
  name: HISTORY_SLICE_NAME,
  initialState,
  reducers: {
    updateHistoryPage: (state, action) => {
      state.listing.page = action.payload
    },
  },
  extraReducers: (builder) => {
    builder

      .addMatcher(isFulfilledAction(`${HISTORY_SLICE_NAME}/`), (state, action) => {
        state.isLoading = false
        Object.keys(action?.payload || {}).forEach((key) => {
          state[key as keyof HistoryState] = action.payload[key]
        })
      })
      .addMatcher(isPendingAction(`${HISTORY_SLICE_NAME}/`), (state) => {
        state.isLoading = true
      })
      .addMatcher(isRejectedAction(`${HISTORY_SLICE_NAME}/`), (state) => {
        state.isLoading = false
      })
  },
})

export const transactionHistorySelector = (state) => {
  return state?.history?.listing?.results?.map(({ hero, updatedAt, status, price, paymentToken, unixTimestamp }) => {
    const character = gameCharacter?.[hero?.heroRarity]?.[hero?.heroName]

    return {
      tokenId: hero?.tokenId,
      nftAddress: hero?.nftAddress,
      paymentToken,
      price: price || '',
      img: character?.img,
      name: character?.name,
      date: unixTimestamp ? new Date(unixTimestamp) : updatedAt,
      status,
    }
  })
}
export const paginationHistorySelector = (state) => {
  const { results, ...pagination } = state.history.listing
  return pagination
}

export const { updateHistoryPage } = historySlicer.actions
export default historySlicer.reducer
