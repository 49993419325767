import { TRANSACTION_STATUS } from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { fetchTransactionHistory } from 'state/history/actions'
import { paginationHistorySelector, transactionHistorySelector, updateHistoryPage } from 'state/history/reducer'
import styled from 'styled-components'
import NoSearch from 'views/NoSearch'
import PaginationMarket from '../PaginationMarket'
import TransactionHistory from './components/TransactionHistory'
const { CANCEL, SELL, SOLD, BOUGHT, MINT } = TRANSACTION_STATUS

function History(props) {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const routerHistory = useHistory()
  const pageNumber = params.get('page') || 1
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()
  const pagination = useSelector(paginationHistorySelector)
  useEffect(() => {
    if (account) {
      dispatch(
        fetchTransactionHistory({
          accountAddress: account,
          page: pageNumber || 1,
          sortBy: 'unixTimestamp:desc',
          status: [CANCEL, SOLD, BOUGHT, MINT],
        }),
      )
    }
  }, [account, pageNumber])
  const transactionHistory = useSelector(transactionHistorySelector)
  const changePageHandler = (page) => {
    dispatch(updateHistoryPage(page))
    // document.querySelector('#root').scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth',
    // })
    routerHistory.push(`/history?page=${page}`)

    // dispatch(fetchListingCivilians())
  }
  const paginationMarketProps = {
    changePageHandler,
    limit: pagination?.limit,
    marketPage: +pageNumber,
    totalResults: pagination?.totalResults,
  }

  return (
    <HistoryStyled>
      <h3>Transaction history</h3>
      <div className="transaction__history">
        {transactionHistory && Array.isArray(transactionHistory) && transactionHistory?.length > 0 ? (
          transactionHistory?.map((history, index) => <TransactionHistory {...history} key={index} />)
        ) : (
          <NoSearch />
        )}
      </div>

      <PaginationMarket {...paginationMarketProps} />
    </HistoryStyled>
  )
}

const HistoryStyled = styled.div`
  width: 75%;
  @media only screen and (max-width: 1024px) {
    width: 90%;
  }
  margin: 0 auto;

  h3 {
    font-size: 24px;
    font-weight: 800;
    color: #fff;
    margin: 0;
    margin-bottom: 40px;
  }
  .transaction__history {
    .transaction__style:nth-child(1) {
      padding-top: 0px;
    }
    .transaction__style:last-child {
      border-bottom: unset;
    }
  }
`
export default History
