import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { fetchSellerFee } from 'state/marketplace/actions/marketActions'
import { ownedInventoryCharactersSelector } from 'state/marketplace/selectors/selector'
import styled from 'styled-components'
import NoSearch from 'views/NoSearch'
import { fetchBuyerFee } from '../../../state/marketplace/actions/marketActions'
import { State } from '../../../state/types'
import TabsCivilian from '../Civilian/components/TabsCivilian'
import Civilian from './Civilian'

const inventoryTabList = [
  {
    name: 'Civilian',
    img: '/images/market/mascot-dpt.png',
  },
  // {
  //   name: 'Vehicle',
  //   img: './images/market/vehicle.png',
  // },
  {
    name: 'On sale',
    img: './images/market/marketplace.png',
  },
]

function Inventory({ listings, currentTab, handleChangeTab, changePageHandler, limit, marketPage, totalResults }) {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchSellerFee())
    dispatch(fetchBuyerFee())
  }, [])
  const paginationMarketProps = {
    changePageHandler,
    limit,
    marketPage,
    totalResults,
  }
  return (
    <InventoryStyled>
      <TabsCivilian tabList={inventoryTabList} currentTab={currentTab} setTab={handleChangeTab} />
      {Array.isArray(listings) && listings?.length > 0 ? (
        <Civilian listings={listings} {...paginationMarketProps} status={currentTab === 'Civilian' ? 0 : 1} />
      ) : (
        <NoSearch />
      )}
    </InventoryStyled>
  )
}

const InventoryStyled = styled.div``
export default Inventory
