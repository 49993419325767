import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import { addCommas } from 'utils/formatNumber'
import { getPaymentToken } from 'config/constants/marketplace'

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;

  .card__header__left {
    text-align: left;
    margin-bottom: 12px;
    p {
      font-weight: 400;
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .civilian_id {
    background: rgba(75, 138, 183, 0.3);
    padding: 6px 12px;
    align-self: flex-start;
    border-radius: 4px;
    margin-bottom: 0;
  }
`

const BodyStyled = styled.div`
  position: relative;

  .rating {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;

    .rating_star {
      margin-bottom: 8px;
    }
  }

  .job {
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 800;
    font-size: 1.25rem;
  }

  .price {
    font-weight: 800;
    color: #ff900e;
    font-size: 1.125rem;

    display: inline-flex;

    img {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
  // .civilianImg {
  //   padding-bottom: 100%;
  //   height: 0;
  //   position: relative;
  //   display: block;
  // }
  .civilianImg img {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 200px;
    height: 200px;
  }
  .tilde {
    vertical-align: sub;
  }

  .rate_usd {
    margin-top: 9px;
    margin-bottom: 0;
    font-size: 1rem;
    opacity: 80%;
  }
`

const CivilianCardInfo = ({ civilianInfo }) => {
  const {
    marketImg,
    level,
    heroRarity,
    heroRating,
    name: job,
    price,
    tokenId,
    tokenImg,
    nftAddress,
    paymentToken,
    status,
  } = civilianInfo

  const priceDisplayed = price ? addCommas(price) : ''

  return (
    <>
      <HeaderStyled>
        <div className="card__header__left">
          <p>Lv.{level}</p>
          <p>Ratings : {Number(heroRating) + 1}</p>
        </div>

        <p className="civilian_id">#{tokenId}</p>
      </HeaderStyled>

      <BodyStyled>
        <div className="rating">
          {heroRarity !== -1 &&
            [...Array(parseInt(heroRarity) + 1)].map(() => (
              <img src="/images/market/star.svg" alt="star" className="rating_star" />
            ))}
        </div>

        <Link className="civilianImg" to={`/detail/${nftAddress}_${tokenId}`}>
          <img src={marketImg} alt="magician" />
        </Link>

        <p className="job">{job}</p>

        <div className="price">
          {!!status && <img src={`./images/tokens/${getPaymentToken(paymentToken).img}`} alt="dpt-icon" />}
          <span>{priceDisplayed}</span>
        </div>
      </BodyStyled>
    </>
  )
}

export default CivilianCardInfo
