// eslint-disable-next-line
import { HISTORY_SLICE_NAME } from './reducer'

interface ACTION {
  [key: string]: string
}
export const HISTORY_ACTION: ACTION = {
  GET_TRANSACTION_HISTORY: `GET_TRANSACTION_HISTORY`,
}
Object.keys(HISTORY_ACTION).forEach((key) => {
  HISTORY_ACTION[key] = `${HISTORY_SLICE_NAME}/${HISTORY_ACTION[key]}`
})
