import { getRawCallData } from 'state/history/utils'

export const getCallData = {
  approve: ({ tokenAddress, addressNeedApprove }) => {
    return getRawCallData('approve', [tokenAddress, addressNeedApprove])
  },
  setApprovalForAll: ({ addressNeedApprove, nftAddress }) => {
    return getRawCallData('setApprovalForAll', [addressNeedApprove, nftAddress])
  },
  addListing: ({ tokenId, nftAddress }) => {
    return getRawCallData('addListing', [nftAddress, tokenId])
  },
  purchaseListing: ({ tokenId, nftAddress }) => {
    return getRawCallData('purchaseListing', [nftAddress, tokenId])
  },
  cancelListing: ({ tokenId, nftAddress }) => {
    return getRawCallData('cancelListing', [nftAddress, tokenId])
  },
}
