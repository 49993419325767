import { TransactionResponse } from '@ethersproject/providers'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useActiveWeb3React } from 'hooks'
import { AppDispatch, AppState, useAppDispatch } from '../index'
import { addTransaction } from './actions'
import { TransactionDetails, TransactionStatus } from './reducer'

export const useTransactionAdder = () => {
  const dispatch = useAppDispatch()

  return ({ callData }) => {
    dispatch(addTransaction({ callData }))
  }
}

// returns all the transactions for the current chain
export function useAllTransactions(): { [txHash: string]: TransactionDetails } {
  // const state = useSelector<AppState, AppState['transactions']>((s) => s.transactions)
  const state = useSelector<AppState, any>((s) => s.transactions)

  return state
}

export const useHasPendingTransaction = (callData: string) => {
  const transactions = useAllTransactions()
  if (transactions[callData]?.status === TransactionStatus.pending) return true

  return false
}
