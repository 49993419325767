import { Text } from '@diviner-protocol/uikit'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { SCAN_SITES } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { getBscScanLink } from '../../utils'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
import { ExternalLink } from '../Shared'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;

  div {
    color: #000;
    font-weight: 700;
    font-size: 20px;
  }
  a {
    color: #575555;
    font-weight: 700;
    font-size: 20px;
    text-decoration-line: underline;
  }
`

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string
  success?: boolean
  summary?: string
}) {
  const { chainId } = useActiveWeb3React()

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>{success ? <CheckCircleSvg /> : <AlertCircleSvg />}</div>
      <AutoColumn gap="8px">
        <Text>{summary ?? `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`}</Text>
        {chainId && (
          <ExternalLink href={getBscScanLink(chainId, hash, 'transaction')}>View on {SCAN_SITES[chainId]}</ExternalLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  )
}

const CheckCircleSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 74 74" fill="none">
    <circle cx="37" cy="37" r="37" fill="#05AE90" />
    <path
      d="M15 34.5L30.2929 49.7929C30.6834 50.1834 31.3166 50.1834 31.7071 49.7929L58.5 23"
      stroke="#ECF9FF"
      strokeWidth="10"
    />
  </svg>
)

const AlertCircleSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 74 74" fill="none">
    <circle cx="37" cy="37" r="37" fill="#FF2525" />
    <line x1="26.364" y1="25" x2="48.9914" y2="47.6274" stroke="white" strokeWidth="9" strokeLinecap="round" />
    <line x1="25.4707" y1="48.1482" x2="48.0981" y2="25.5208" stroke="white" strokeWidth="9" strokeLinecap="round" />
  </svg>
)
