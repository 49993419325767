import { useWeb3React } from '@web3-react/core'
import { Col, Row } from 'antd'
import { FILTER_OPTION, HERO_STATUS } from 'constants/index'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useHistory, useLocation } from 'react-router-dom'

import { resetFilter, setMarketplaceState } from 'state/marketplace'
import { updateMarketPage } from 'state/marketplace/actions/actions'
import { fetchListCivilians } from 'state/marketplace/actions/marketActions'
import {
  filtersSelector,
  listedCharactersSelector,
  paginationSelector,
  reboundSelector,
  sortSelector,
} from 'state/marketplace/selectors/selector'
import { State } from 'state/types'
import styled from 'styled-components'
import Inventory from './components/Inventory'
import Sidebar from './components/Sidebar'

function PageInventory(props) {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const history = useHistory()
  const pageNumber = params.get('page') || 1
  const dispatch = useAppDispatch()

  const filterOptions = [
    // {
    //   type: FILTER_OPTION.SEARCH,
    // },
    {
      label: 'Rarity',
      type: FILTER_OPTION.RARITY,
    },
    {
      label: 'Class',
      type: FILTER_OPTION.CLASS,
    },

    {
      label: 'Level',
      type: FILTER_OPTION.LEVEL,
    },
  ]
  const { account } = useWeb3React()
  const [currentTab, setTab] = useState('Civilian')

  const rebound = useSelector(reboundSelector)
  const listings = useSelector(listedCharactersSelector)
  const isFirstRender = useRef(true)
  const { limit, marketPage, totalResults } = useSelector(paginationSelector)
  const filters = useSelector(filtersSelector)
  const sorts = useSelector(sortSelector)

  const isFetchingMarket = useSelector((state: State) => state.marketplace.fetching.market)

  // useEffect(() => {
  //   if (!account) navigate('/')
  // }, [account])

  useEffect(() => {
    dispatch(resetFilter())
  }, [])
  useEffect(() => {
    filterData()
    // document.querySelector('#root').scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth',
    // })
  }, [filters, sorts, pageNumber, account, currentTab])

  useEffect(() => {
    if (rebound) {
      filterData()
    }
  }, [rebound])
  const changePageHandler = (page) => {
    dispatch(updateMarketPage(page))
    history.push(`/inventory?page=${page}`)

    // dispatch(fetchListingCivilians())
  }
  const filterData = () => {
    if (account) {
      let filter = {
        status: currentTab === 'Civilian' ? HERO_STATUS.ON_INVENTORY : HERO_STATUS.ON_SALE,
        limit,
        page: pageNumber,
        accountAddress: account,
      }
      if (!isFirstRender.current) {
        const { isOwned, rangePrice, ...rest } = filters
        const updatedSort = Object.keys(sorts).reduce((accmu, key) => {
          // eslint-disable-next-line
          return (accmu += `${key}:${sorts[key] === 1 ? 'asc' : 'desc'},`)
        }, '')
        filter = Object.assign(
          filter,
          {
            ...filter,
            ...rest,
            price: (rangePrice?.from || rangePrice?.to) && `${rangePrice?.from || ''},${rangePrice?.to || ''}`,
          },
          {
            sortBy: updatedSort,
          },
        )
      } else {
        isFirstRender.current = false
      }

      dispatch(
        fetchListCivilians(Object.fromEntries(Object.entries(filter).filter(([_, v]) => (v === 0 ? true : !!v)))),
      )
    }
  }
  const handleChangeFilter = (payload) => {
    dispatch(updateMarketPage(1))
    history.push(`/inventory?page=1`)
    dispatch(setMarketplaceState(payload))
  }
  const sidebarProps = {
    filterOptions,
    handleChangeFilter,
  }
  const updatedListings = listings.map((item) => ({
    ...item,
    seller: currentTab === 'Civilian' ? '' : item.seller,
    price: currentTab === 'Civilian' ? '' : item.price,
  }))
  const handleChangeTab = (updatedTab) => {
    setTab(updatedTab)
    history.push(`/inventory?page=1`)
    dispatch(updateMarketPage(1))
  }
  const inventoryProps = {
    listings: updatedListings,
    handleChangeTab,
    currentTab,
    changePageHandler,
    limit,
    marketPage: +pageNumber,
    totalResults,
  }
  return (
    <div className="marketPage">
      <PageInventoryStyled>
        <Row gutter={[48, 16]}>
          <Col sm={24} md={24} lg={12} xl={6}>
            <Sidebar {...sidebarProps} />
          </Col>
          <Col sm={24} md={24} lg={12} xl={18}>
            <Inventory {...inventoryProps} />
          </Col>
        </Row>
      </PageInventoryStyled>
    </div>
  )
}

const PageInventoryStyled = styled.div`
  padding: 0 60px;

  @media only screen and (max-width: 1024px) {
    padding: 0 40px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 15px;
  }

  .ant-col {
    flex-grow: 1;
  }
`
export default PageInventory
