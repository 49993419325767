import React from 'react'
import { useActiveWeb3React } from 'hooks'
import GlobalModal from 'components/static/GlobalModal'
import ConnectWalletModal from './Modal/ConnectWalletModal'
import styled from 'styled-components'

function NoSearch(props) {
  const { account } = useActiveWeb3React()
  return (
    <NoSearchStyled>
      {!account ? (
        <button
          className="header__button"
          type="submit"
          onClick={() => {
            GlobalModal.show(<ConnectWalletModal onClose={GlobalModal.hide} />)
          }}
        >
          <p>Connect wallet</p>
          <img src="./images/icon-wallet.png" alt="connect wallet" />
        </button>
      ) : (
        <p>No search results</p>
      )}
    </NoSearchStyled>
  )
}

const NoSearchStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #94bfff;
  font-size: 20px;
  padding-top: 50px;

  .header__button {
    background: url('./images/market/header-button.png') no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    width: 100%;
    max-width: 160px;
    height: 46px;
    line-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    padding: 0 20px;
    transform: scale(1);
    transition: 0.5s ease-in-out;
    border: none;

    @media (max-width: 767px) {
      background: unset;
      position: absolute;
      top: 11px;
      right: -61px;
    }

    &:hover {
      transform: scale(1.1);
      transition: 0.5s ease-in-out;
    }

    p {
      color: #fff;
      font-size: 16px;
      word-spacing: -5px;
      margin-bottom: 0;
      display: none;
      white-space: nowrap;

      @media (min-width: 767px) {
        display: block;
      }
    }

    img {
      display: block;
      max-width: 30px;

      @media (min-width: 767px) {
        display: none;
      }
    }
  }
`
export default NoSearch
