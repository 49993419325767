const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },

  cake: {
    symbol: 'CAKE',
    address: {
      56: '0xF61e1c34386ADD959937a99117C1fa3fE7DFf2F7',
      97: '0xF61e1c34386ADD959937a99117C1fa3fE7DFf2F7',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  wbnb: {
    symbol: 'wBNB',
    address: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },

  dpt: {
    symbol: 'DPT',
    address: {
      4: '0xE9D583aDF709001dfcEad8875a46F2b784b6ab71',
      56: '0xE69cAef10A488D7AF31Da46c89154d025546e990',
      97: '0xf0293f31359b7B394445C9B2bE4cd215FA14ccB7',
    },
    decimals: 18,
  },
  dgt: {
    symbol: 'DGT',
    address: {
      56: '0x4d87EE06A797Ea69f39Bf47C0505a3866Ced1273',
      97: '0x683968E4ee884563315746e7DECDF37C2A075147',
    },
    decimals: 18,
  },
  busd: {
    symbol: 'BUSD',
    address: {
      4: '0x965919bCb51cCE4FeB5aE062f710226037738e00',
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '0x35606cA16E901E32Fe52cbD172317141bDAB8afb',
    },
    decimals: 18,
  },

  usdtBusdLp: {
    symbol: 'Cake-LP',
    address: {
      56: '0x2C7Ab52226EDCA513C6FDa19263f2F444747BC84',
      97: '0x2C7Ab52226EDCA513C6FDa19263f2F444747BC84',
    },
    decimals: 18,
  },
}

export default tokens
