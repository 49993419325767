import { InputNumber } from 'antd'
import { filter } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { filtersSelector } from 'state/marketplace/selectors/selector'
import styled from 'styled-components'

function PriceFilter({ handleChangeFilter }) {
  const filters = useSelector(filtersSelector)
  const [min, setMin] = useState(null)
  const [max, setMax] = useState(null)
  const handleBlur =
    (isFrom = true) =>
    (e) => {
      const newFromValue = isFrom ? (e.target.value === '' ? '' : Number(e.target.value)) : min ?? ''
      const newToValue = isFrom ? max ?? '' : e.target.value === '' ? '' : Number(e.target.value)
      if (!(newFromValue === '' || newToValue === '') && newFromValue > newToValue) {
        handleChangeFilter({
          filters: {
            ...filters,
            rangePrice: {
              from: newFromValue,
              to: newFromValue,
            },
          },
        })
        setMin(newFromValue)
        setMax(newFromValue)
      } else {
        handleChangeFilter({
          filters: {
            ...filters,
            rangePrice: {
              from: newFromValue,
              to: newToValue,
            },
          },
        })
        if (isFrom) setMin(newFromValue)
        else setMax(newToValue)
      }
    }
  useEffect(() => {
    setMin(filters?.rangePrice?.from || null)
    setMax(filters?.rangePrice?.to || null)
  }, [filters.rangePrice])

  return (
    <PriceFilterStyled>
      <div className="filter__field">
        <InputNumber
          onBlur={handleBlur()}
          onChange={setMin}
          type="number"
          className="input-price"
          placeholder="Min"
          value={min}
          min={0}
          controls={false}
        />
        <div className="filter__field-label"> To</div>
        <InputNumber
          onBlur={handleBlur(false)}
          onChange={setMax}
          type="number"
          className="input-price"
          placeholder="Max"
          value={max}
          min={0}
          controls={false}
        />
      </div>
    </PriceFilterStyled>
  )
}

const PriceFilterStyled = styled.div`
  .filter__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 16px;

    &-label {
      margin: 0 10px;
    }

    .input-price {
      width: 100%;
      border-radius: 76px;
      background: #011446;
      border: unset;
      height: 36px;
      padding: 10px;
      height: 46px;
      &::placeholder {
        color: #fff;
      }
      input.ant-input-number-input {
        color: #fff;
      }
    }
  }
`
export default PriceFilter
