// eslint-disable-next-line
import { TRANSACTION_STATUS } from '../../../../constants'
import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { tokenSymbol } from 'config/constants/game'
import { Link } from 'react-router-dom'
const { CANCEL, SELL, SOLD, BOUGHT, MINT } = TRANSACTION_STATUS
function TransactionHistory({ img, name, price, date, status, paymentToken, nftAddress, tokenId }) {
  const statusText = {
    [CANCEL]: 'CANCEL',
    [SOLD]: 'SOLD',
    [SELL]: 'ON SALE',
    [BOUGHT]: 'BOUGHT',
    [MINT]: 'MINT',
  }

  return (
    <TransactionHistoryStyled className="transaction__style">
      <div className="history__left">
        <div className="history__img">
          <Link className="civilianImg" to={`/detail/${nftAddress}_${tokenId}`}>
            <img src={img} alt="img" />
          </Link>
        </div>

        <div className="history__content">
          <p className="history__name">{name}</p>
          <p
            style={
              status === CANCEL
                ? { color: '#747FA0' }
                : status === SOLD
                ? { color: '#4BAE4F' }
                : status === SELL
                ? { color: '#897FE5' }
                : { color: '#F34235' }
            }
            className="history__price"
          >
            {status !== CANCEL &&
              `${status === SOLD ? '+' : status === BOUGHT ? '-' : ''} ${price} ${tokenSymbol[paymentToken] || ''}`}
          </p>
        </div>
      </div>
      <div className="history__right">
        <div className="history__status">
          <p
            style={
              status === CANCEL
                ? { background: '#747FA0' }
                : status === SOLD
                ? { background: '#4BAE4F' }
                : status === SELL
                ? { background: '#897FE5' }
                : { background: '#F34235' }
            }
          >
            {statusText?.[status]}
          </p>
        </div>

        <p className="history__date">
          <span>{new Date(date).toLocaleString()}</span>
        </p>
      </div>
    </TransactionHistoryStyled>
  )
}

const TransactionHistoryStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 28px;
  border-bottom: 1px solid #376bb9;
  padding-top: 28px;

  p {
    margin: 0;
  }
  .history__left {
    display: flex;
    justify-content: right;
    p {
      font-size: 18px;
      font-weight: 800;
      color: #fff;
      padding-bottom: 18px;
      @media only screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
  }
  .history__right {
    text-align: right;

    .history__status {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
      p {
        width: max-content;
        padding: 6px 12px;
        font-weight: 800;
        font-size: 14px;
        color: #fff;
        text-transform: uppercase;
        border-radius: 18px;
      }
    }
    .history__date {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
    }
  }
  .history__img {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #263c77;
    border-radius: 12px;
    border: 1.5px solid #2d4790;
    margin-right: 15px;
    img {
      width: 100%;
      height: 100%;
    }
  }
`
export default TransactionHistory
