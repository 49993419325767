import { createSelector } from '@reduxjs/toolkit'
import { State } from 'state/types'
import { useWeb3React } from '@web3-react/core'
import gameCharacter, { tokenImg } from 'config/constants/game'

export const paginationSelector = (state: State) => state.marketplace.pagination

export const sortSelector = (state: State) => state.marketplace.sorts
// export const sortByPriceSelector = (state: State) => state.marketplace.sorts?.byPrice
// export const sortByLevelSelector = (state: State) => state.marketplace.sorts?.byLevel
// export const sortByRaritySelector = (state: State) => state.marketplace.sorts?.byRarity

export const marketplaceFetchingSelector = (state: State) => state.marketplace.fetching
export const reboundSelector = (state: State) => state.marketplace.rebound
export const filtersSelector = (state: State) => state.marketplace.filters
export const filtersByOwnershipSelector = (state: State) => state.marketplace.filters.isOwned
export const filterByRankSelector = (state: State) => state.marketplace.filters.heroRarity
export const filterByLevelSelector = (state: State) => state.marketplace.filters.level
export const filterByRangePriceSelector = (state: State) => state.marketplace.filters.rangePrice
export const listedCharactersSelector = (state: State) => {
  if (state?.marketplace?.listing?.length)
    return state?.marketplace?.listing?.map((item) => ({
      ...item,
      ...gameCharacter[item?.heroRarity]?.[item?.heroName],
      tokenImg: tokenImg[item.paymentToken],
      seller: item?.accountAddress,
    }))
  return []
}
export const ownedInventoryCharactersSelector = (state: State) => Object.values(state.marketplace.inventoryCharacters)
export const onSaleCharactersSelector = (state: State) => Object.values(state.marketplace.onSaleCivilians)

export const fetchingSelector = (state: State) => state.marketplace.fetching
export const isApprovingSelector = (state: State) => state.marketplace.approving
export const fetchUserDptToken = (state: State) => state.marketplace.user.dpt
export const fetchIsApprovedForAll = (state: State) => state.marketplace.isApprovedForAll

export const detailCivilianSelector = (state: State) => state.marketplace.civilian

export const errorMessageSelector = (state: State) => state.marketplace.error

export const loadedSelector = (state: State) => state.marketplace.loaded

export const filteredListedCharactersSelector = createSelector(
  [
    listedCharactersSelector,
    filtersByOwnershipSelector,
    filterByRankSelector,
    filterByLevelSelector,
    filterByRangePriceSelector,
    // sortByPriceSelector,
  ],
  (listedCivilians, isOwner, rank, level, rangePrice) => {
    const { account } = useWeb3React()

    if (isOwner) {
      listedCivilians = listedCivilians.filter((civilian) => civilian.seller === account)
    }

    // if (rank) {
    //   listedCivilians = listedCivilians.filter((character) => parseInt(character.heroRarity) + 1 === rank)
    // }

    // if (level && level.length > 0) {
    //   listedCivilians = listedCivilians.filter(
    //     (character) =>
    //       !character.level || (parseInt(character.level) >= level[0] && parseInt(character.level) <= level[1]),
    //   )
    // }

    if (rangePrice.from > 0) {
      listedCivilians = listedCivilians.filter(
        (character) => character.price && parseFloat(character.price) >= rangePrice.from,
      )
    }

    if (rangePrice.to > 0) {
      listedCivilians = listedCivilians.filter(
        (character) => character.price && parseFloat(character.price) <= rangePrice.to,
      )
    }

    // return listedCivilians.sort((a, b) => (parseFloat(a.price) - parseFloat(b.price)) * order)
    return listedCivilians
  },
)

// export const filteredInventoryCharactersSelector = createSelector(
//   ownedInventoryCharactersSelector,
//   sortByLevelSelector,
//   sortByRaritySelector,
//   (civilians: any, levelOrder: number, rarityOrder: number) => {
//     if (levelOrder > 0) return civilians.sort((a, b) => parseFloat(b.level) - parseFloat(a.level))

//     if (rarityOrder > 0) return civilians.sort((a, b) => parseFloat(b.heroRarity) - parseFloat(a.heroRarity))

//     return civilians
//   },
// )
