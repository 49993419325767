import { TRANSACTION_STATUS } from 'constants/index'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { resetFilter } from 'state/marketplace'
import { fetchDetailCivilian } from 'state/marketplace/actions/marketActions'
import { detailCivilianSelector, fetchingSelector } from 'state/marketplace/selectors/selector'
import styled from 'styled-components'
import Detail from './components/Detail.tsx'
import PageLoading from './PageLoading'
const { CANCEL, SELL, SOLD, BOUGHT, MINT } = TRANSACTION_STATUS

function PageDetail(props) {
  const { id: idParam } = useParams<any>()
  const fetching = useSelector(fetchingSelector)
  const dispatch = useAppDispatch()
  const [tokenAddress, tokenId] = idParam.split('_')
  const civilian = useSelector(detailCivilianSelector)

  useEffect(() => {
    // if (isEmpty(civilian)) {
    dispatch(
      fetchDetailCivilian({
        tokenId,
        params: {
          hero: +tokenId,
          status: [CANCEL, SOLD, BOUGHT, MINT],
        },
      }),
    )

    // }
    return () => {
      dispatch(resetFilter())
    }
  }, [idParam])

  return (
    <PageDetailStyled>
      {fetching.detail === true ? (
        <PageLoading />
      ) : (
        <>
          <Detail civilian={civilian} />
        </>
      )}
    </PageDetailStyled>
  )
}

const PageDetailStyled = styled.div`
  background: #15244e;
  width: 100%;
  height: 100vh;
`
export default PageDetail
