import React from 'react'
import styled from 'styled-components'

function ButtonMarket({ name, img, onClick = null, disable = false }) {
  return (
    <ButtonMarketStyled className={disable === true ? "button-market disable" : "button-market"}  onClick={disable ? null : onClick}>
      <div className="button-market-content">
        <img src={img} alt="img button" />
        <p>{disable ? 'Processing' : name}</p>
      </div>
    </ButtonMarketStyled>
  )
}

export const ButtonMarketStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ease all 0.5s;
  background: #2f93f0;
  width: 185px;
  height: 56px;
  border-radius: 55px;
  position: relative;
  
  &::before {
    position: absolute;
    content: '';
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    background: #7ed5ff;
    width: 145px;
    height: 19px;
    top: 4px;
    border-radius: 162px;
    text-align: center;
    z-index: 2;
  }
  p {
    margin: 0;
    padding-left: 10px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    @media only screen and (max-width: 600px) {
      font-size: 18px;
    }
  }

  &::after {
    position: absolute;
    content: '';
    background: #68c9ff;
    width: 175px;
    height: 50px;
    top: 0;
    border-radius: 162px;
    z-index: 1;
  }

  &:hover {
    transform: scale(1.1);
  }

  .button-market-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;

    p {
      line-height: 0;
    }
  }
`
export default ButtonMarket
