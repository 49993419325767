import { createAsyncThunk } from '@reduxjs/toolkit'
import characterAbi from 'config/abi/character.json'
import marketplaceAbi from 'config/abi/DivinerMarketplace.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import { groupBy, keyBy, map, merge, values as _values } from 'lodash'
import { fetchingInventory } from 'state/marketplace/actions/actions'
import { _getCiviliansByIds, _getListingDataByIdentifiers } from 'state/marketplace/utils'
import { getCharacterAddress, getFakeCharacterAddress, getMarketplaceAddress } from 'utils/addressHelpers'
import { getCharacterContract, getFakeCharacterContract, getMarketplaceContract } from 'utils/contractHelpers'
import { fetchingOnSale } from './actions'

// export const fetchInventoryCivilians = createAsyncThunk(
//   'marketplace/fetchInventoryCivilians',
//   async (account: string, { dispatch }) => {
//     let civilians = []
//     let civilianIds = []
//     let fakeCivilianIds = []

//     try {
//       dispatch(fetchingInventory(true))
//       const civiliansContract = getCharacterAddress() !== '' && getCharacterContract()
//       const fakeCiviliansContract = getFakeCharacterAddress() !== '' && getFakeCharacterContract()

//       await Promise.all([
//         civiliansContract && civiliansContract?.tokensOfOwner(account),
//         fakeCiviliansContract && fakeCiviliansContract?.tokensOfOwner(account),
//       ]).then((values) => {
//         civilianIds = values[0]
//         fakeCivilianIds = values[1]
//       })

//       await Promise.all([
//         _getCiviliansByIds(getCharacterAddress(), characterAbi, 'getHero', civilianIds, true),
//         _getCiviliansByIds(getFakeCharacterAddress(), fakeCharacterAbi, 'getHero', fakeCivilianIds, false),
//       ]).then((values) => {
//         civilians = [..._values(keyBy(values[0], '_id')), ..._values(keyBy(values[1], '_id'))]
//       })

//       dispatch(fetchingInventory(false))
//       return civilians
//     } catch (e) {
//       return []
//     }
//   },
// )

export const fetchOnSaleCivilians = createAsyncThunk(
  'marketplace/fetchOnSaleCivilians',
  async (account: string, { dispatch }) => {
    try {
      dispatch(fetchingOnSale(true))
      const listingCivilians = await _fetchListingInventoryCivilians(account)
      const listingCivilianIds = map(listingCivilians[getCharacterAddress()], 'id')
      const civiliansInfo = await _getCiviliansByIds(
        getCharacterAddress(),
        characterAbi,
        'getHero',
        listingCivilianIds,
        true,
      )

      const civilians = _values(
        merge(keyBy(civiliansInfo, '_id'), keyBy(listingCivilians[getCharacterAddress()], '_id')),
      )

      dispatch(fetchingOnSale(false))

      return civilians
    } catch (e) {
      return []
    }
  },
)

const _fetchListingInventoryCivilians = async (account) => {
  const marketplaceContract = getMarketplaceContract()
  const listingTokens = await marketplaceContract?.getListingBySeller(account)

  const results = await _getListingDataByIdentifiers(
    getMarketplaceAddress(),
    marketplaceAbi,
    'getListingByIdentifier',
    listingTokens,
  )

  return groupBy(results, 'listingNft')
}
