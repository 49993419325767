import { useQueryListings } from 'hooks/useQueryMarketplace'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import styled from 'styled-components'
import NoSearch from 'views/NoSearch'
import PaginationMarket from '../PaginationMarket'
import CardSkeleton from './components/CardSkeleton'
import PlayGames from './components/PlayGames'
import TabsCivilian from './components/TabsCivilian'
import CiviliansListing from './ItemContainer'
import { fetchAmountCiviliansListing } from '../../../state/marketplace/actions/marketActions'
import { HERO_STATUS } from '../../../constants'

export const ListCardContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2em;
  @media (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const tabList = [
  {
    name: 'Civilian',
    img: '/images/market/mascot-dpt.png',
  },
  // {
  //   name: 'Vehicle',
  //   img: './images/market/vehicle.png',
  // },
]

function Marketplace({ isFetchingMarket, listings, changePageHandler, limit, marketPage, totalResults }) {
  const [currentTab, setTab] = useState('Civilian')
  const dispatch = useAppDispatch()
  // const { data: listingCivilians, fetching, error } = useQueryListings()

  // useEffect(() => {
  // }, [])

  const paginationMarketProps = {
    changePageHandler,
    limit,
    marketPage,
    totalResults,
  }

  return (
    <CivilianStyled>
      <PlayGames />
      <div className="civilian__tabs">
        <TabsCivilian tabList={tabList} currentTab={currentTab} setTab={setTab} />
        {Array.isArray(listings) && listings?.length > 0 ? (
          <>
            <ListCardContainerStyled>
              {isFetchingMarket ? [...Array(4)].map(() => <CardSkeleton />) : <CiviliansListing listings={listings} />}
            </ListCardContainerStyled>
            {!isFetchingMarket && <PaginationMarket {...paginationMarketProps} />}
          </>
        ) : (
          <NoSearch />
        )}
      </div>
    </CivilianStyled>
  )
}

const CivilianStyled = styled.div`
  /* padding:0px 60px; */
`
export default Marketplace
