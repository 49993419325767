import { useWeb3React } from '@web3-react/core'
import GlobalModal from 'components/static/GlobalModal'
import { utils, ethers } from 'ethers'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { setMarketplaceState } from 'state/marketplace'
import { calculateGasMargin } from 'utils'
import { getCallData } from 'utils/calldata'
import { notification, Spin } from 'antd'
import TransactionModal from 'views/Modal/TransactionModal'
import {
  addTransaction,
  clearTransaction,
  errorTransaction,
  finalizeTransaction,
  updateTxHash,
  clearTransactionNotConfirmed,
} from '../state/transactions/actions'
import { State } from '../state/types'
import { useMarketplaceContract } from './useContract'
import { marketplaceFetchingSelector } from 'state/marketplace/selectors/selector'
import { setUserState } from 'state/user/reducer'
import { getCharacterAddress } from 'utils/addressHelpers'
import { fetchDetailCivilian } from 'state/marketplace/actions/marketActions'
import { TRANSACTION_STATUS } from 'constants/index'
const { CANCEL, SELL, SOLD, BOUGHT, MINT } = TRANSACTION_STATUS

const DENY_TX_MESSAGE = 'MetaMask Tx Signature: User denied transaction signature.'

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useCancelListing(characterAddress, tokenId) {
  const fetching = useSelector(marketplaceFetchingSelector)

  const marketplaceContract = useMarketplaceContract()
  const dispatch = useAppDispatch()
  const civilianInInventory = useSelector(
    (state: State) => state.marketplace.inventoryCharacters[`${characterAddress}_${tokenId}`],
  )

  const civilianInMarket = useSelector((state: State) => state.marketplace.listing[`${characterAddress}_${tokenId}`])

  const onCancelListing = useCallback(async () => {
    try {
      dispatch(
        setMarketplaceState({
          fetching: {
            ...fetching,
            button: true,
          },
        }),
      )
      const estimatedGas = await marketplaceContract.estimateGas.cancelListing(characterAddress, tokenId)

      const tx = await marketplaceContract.cancelListing(characterAddress, tokenId, {
        gasLimit: calculateGasMargin(estimatedGas),
      })

      const wait = await tx.wait()

      GlobalModal.show(
        <TransactionModal message="Cancel listing successfully" onClose={GlobalModal.hide} txReceipt={wait} />,
        { position: 'upperRight', dimmerOpacity: '0' },
      )
      const civilian = civilianInMarket ?? civilianInInventory
      dispatch(
        setMarketplaceState({
          rebound: true,
        }),
      )
    } catch (error) {
      console.error('Cancel listing error', error)
      notification.error({
        message: 'Cancel listing failed',
        placement: 'topRight',
      })

      throw error
    } finally {
      dispatch(
        fetchDetailCivilian({
          tokenId,
          params: {
            hero: tokenId,
            status: [CANCEL, SOLD, BOUGHT, MINT],
          },
        }),
      )

      dispatch(
        setMarketplaceState({
          isLoading: false,
          rebound: true,
          fetching: {
            ...fetching,
            button: false,
          },
        }),
      )
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [marketplaceContract, characterAddress, tokenId, civilianInInventory, civilianInMarket])

  return [onCancelListing]
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function usePurchaseListing(characterAddress = getCharacterAddress(), tokenId) {
  const fetching = useSelector(marketplaceFetchingSelector)

  const marketplaceContract = useMarketplaceContract()
  const dispatch = useAppDispatch()
  const civilian = useSelector(
    (state: State) =>
      state.marketplace?.listing?.find((item) => item.tokenId === tokenId) || state.marketplace.civilian,
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onPurchaseListing = useCallback(async () => {
    let msgValue = ethers.utils.parseEther('0')

    if (civilian.paymentToken && civilian.paymentToken === ethers.constants.AddressZero) {
      msgValue = ethers.utils.parseEther(civilian.price.toString())
    }

    try {
      dispatch(
        setMarketplaceState({
          fetching: {
            ...fetching,
            button: true,
          },
        }),
      )
      const estimatedGas = await marketplaceContract.estimateGas.purchaseListing(characterAddress, tokenId, {
        value: msgValue,
      })
      const tx = await marketplaceContract.purchaseListing(characterAddress, tokenId, {
        gasLimit: calculateGasMargin(estimatedGas),
        value: msgValue,
      })
      dispatch(
        setMarketplaceState({
          isLoading: true,
        }),
      )

      const wait = await tx.wait()
      notification.success({
        message: 'Purchase listing successfully',
        placement: 'topRight',
      })
      GlobalModal.show(
        <TransactionModal message="Purchase listing successfully" onClose={GlobalModal.hide} txReceipt={wait} />,
        { position: 'upperRight', dimmerOpacity: '0' },
      )

      dispatch(
        setUserState({
          rebound: true,
        }),
      )
    } catch (error: any) {
      notification.error({
        message: 'Purchase listing failed!',
        description: error?.data?.message,
        placement: 'topRight',
      })
    } finally {
      dispatch(
        fetchDetailCivilian({
          tokenId,
          params: {
            hero: tokenId,
            status: [CANCEL, SOLD, BOUGHT, MINT],
          },
        }),
      )

      dispatch(
        setMarketplaceState({
          isLoading: false,
          rebound: true,
          fetching: {
            ...fetching,
            button: false,
          },
        }),
      )
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [marketplaceContract, characterAddress, tokenId, civilian])

  return [onPurchaseListing]
}

export function useAddListing(tokenId, characterAddress) {
  const marketplaceContract = useMarketplaceContract()
  const fetching = useSelector(marketplaceFetchingSelector)
  const civilian = useSelector(
    (state: State) => state.marketplace.inventoryCharacters[`${characterAddress}_${tokenId}`],
  )
  const sellerFee = useSelector((state: State) => state.marketplace.fee.sellerFee)

  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const addListing = useCallback(
    async (price, paymentToken) => {
      if (!price) return
      let msgValue = ethers.utils.parseEther('0')

      const priceAfterFormatEther = utils.parseEther(price?.toString())

      if (paymentToken === ethers.constants.AddressZero) {
        msgValue = priceAfterFormatEther.mul(ethers.BigNumber.from(sellerFee)).div(ethers.BigNumber.from('100'))
      }
      dispatch(
        setMarketplaceState({
          fetching: {
            ...fetching,
            button: true,
          },
        }),
      )

      const estimatedGas = await marketplaceContract.estimateGas.addListing(
        tokenId,
        priceAfterFormatEther,
        paymentToken,
        characterAddress,
        { value: msgValue },
      )

      try {
        const tx = await marketplaceContract.addListing(
          tokenId,
          priceAfterFormatEther,
          paymentToken,
          characterAddress,
          {
            gasLimit: calculateGasMargin(estimatedGas),
            value: msgValue,
          },
        )

        const wait = await tx.wait()

        GlobalModal.show(
          <TransactionModal message="Add listing successfully" onClose={GlobalModal.hide} txReceipt={wait} />,
          { position: 'upperRight', dimmerOpacity: '0' },
        )
        dispatch(
          setMarketplaceState({
            rebound: true,
          }),
        )
      } catch (error) {
        notification.error({
          message: 'Add listing failed',
          placement: 'topRight',
        })
        console.error('Add listing error', error)
      } finally {
        dispatch(
          fetchDetailCivilian({
            tokenId,
            params: {
              hero: tokenId,
              status: [CANCEL, SOLD, BOUGHT, MINT],
            },
          }),
        )

        dispatch(
          setMarketplaceState({
            isLoading: false,
            rebound: true,
            fetching: {
              ...fetching,
              button: false,
            },
          }),
        )
      }

      /* eslint-disable react-hooks/exhaustive-deps */
    },
    [tokenId, characterAddress, marketplaceContract, sellerFee, civilian],
  )

  return [addListing]
}
