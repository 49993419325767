export default {
  mulltiCall: {
    4: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },

  prediction: {
    56: [
      '0x562b441959Aa00b12d00a1725992C253FB705f05',
      '0x44882ed1C425083424Ed49D26b8D504d6BE1C8Db',
      '0x06456Cd5497E801c40c41856c824a67374a31EE7',
    ],
    97: [
      '0x38A40C0473876CACE7B62edC0C0edeF826edc5b2',
      '0x38A40C0473876CACE7B62edC0C0edeF826edc5b2',
      '0x38A40C0473876CACE7B62edC0C0edeF826edc5b2',
    ],
  },

  game: {
    56: '0x0506871B68E5823693E115a5e2175b9A2c4CFB42',
    97: '0xFe70F3F60894E36BcFD3dc1521aFaFb7A849E0F6',
  },
  character: {
    4: '0xFB6084CA8757ffE91ce1bb2A8DB92F654177b9d4',
    56: '0xcaE918c73E5D5c888AFf942B0B63C0CF4c15dC64',
    97: '0xA9F8bad3F20819F69AA6aFd05F79B5ed98BEA8EC',
  },
  equipment: {
    56: '0xAdD5a0B24E7F987e8FAB863e86BaB1F583c6DFB6',
    97: '0x7EDD7F5aAe70E8F7795E5101F6f672A9Fb1e3cCB',
  },

  priceOracle: {
    56: '0xFBFb4C4E10EBD70DcE28B8b00FdAD6c8e5De38dc',
    97: '0x5aA7C4a25c9E3db2bCAe6E08dC2Bfe8aCed73730',
  },

  gameWiki: {
    56: '0x7d88930d354BcB4cBB2F28eD4561b267baf147fA',
    97: '0xDf4f2E52078135365f33eF5cB541A9e7c0aa1B35',
  },

  faucet: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0xe72A4D14d49568dEE390aCebFf18bD498eDd127D',
  },

  market: {
    4: '0x79015549A71FE77cC62c68c37A1858D98AE08000',
    56: '0x0D18f1Ce23580d1aac91aB010D204C6666B8D081',
    97: '0xEA7eD4b92eD42a1d113F4D2B43D718aA2f83c79b',
  },

  fakeCharacter: {
    4: '',
    56: '',
    97: '0x26656936D36148320a0d83cE75958B0aFE0d9cD2',
  },

  buyChest: {
    56: '0x2Af4A1d58E12de09306AAc5Bc108ce03459BE226',
    97: '0xA7421E3f7A1885813B1C9749Cecae48745992A78',
  },
}
