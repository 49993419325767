import React from 'react'
import styled from 'styled-components'

function MysteryTitle({className,transactionTime}) {
  return (
    <MysteryTitleStyled className={className} style={{animationDelay: `${transactionTime}s`}}>
      <div className="mystery">
        <div className="mystery__title">
          <h2>MYSTERY BOX</h2>
        </div>
      </div>
    </MysteryTitleStyled>
  )
}

const MysteryTitleStyled = styled.div`
  position: absolute;
  top: 73px;
  left: 50%;
  transform: translate(-50%, 0px);
  @media (max-width: 1024px) {
    top: 59px;
  }
  @media (max-width: 769px) {
    top: 65px;
  }
  @media (max-width: 500px) {
    top: 78px;
  }
  .mystery {
    background-image: url('./images/marketv2/bg-mystery.png');
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 510px;
    width: 100%;
    margin: 0 auto;
    h2 {
      font-size: 2rem;
      text-shadow: unset;
      padding: 28px 114px 34px 114px;
      white-space: nowrap;
      @media (max-width: 500px) {
        font-size: 18px;
      }
    }
  }
`
export default MysteryTitle
