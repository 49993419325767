import styled, { css } from 'styled-components'

export const CardButtonStyled = styled.button<{ isLoading: boolean }>`
  margin-top: 24px;
  border: 0;
  width: 100%;
  background: #55b3f8;
  padding: 10px 24px 10px 24px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 1rem;

  ${(props) => {
    if (!props.isLoading) {
      return css`
        &:hover {
          background: #1487db;
          cursor: pointer;
        }
      `
    }
  }}
`

export const MarketCardButtonStyled = styled(CardButtonStyled)<{ isCancel: boolean; isLoading: boolean }>`
  background: ${(props) => (props.isCancel ? '#DE4444' : '#55b3f8')};
  ${(props) => {
    if (!props.isLoading) {
      return css`
        &:hover {
          background: ${props.isCancel ? '#B91919' : '#1487db'};
          cursor: pointer;
        }
      `
    }
  }}
`
