import { ChainId } from '@diviner-protocol/v2-sdk'
import { createAsyncThunk } from '@reduxjs/toolkit'
import erc20ABI from 'config/abi/erc20.json'
import { BigNumber } from 'ethers'
import { getBusdAddress, getDptAddress, getMarketplaceAddress } from 'utils/addressHelpers'
import multicall, { Call } from 'utils/multicall'
import { simpleRpcProvider } from 'utils/providers'
import { getChainId } from 'utils/web3React'
import { getCharacterAddress } from '../../../utils/addressHelpers'
import { getErc721Contract } from '../../../utils/contractHelpers'

export const fetchApprovalNftMarket = createAsyncThunk(
  'marketplace/fetchApprovalNftMarket',
  async (account: string): Promise<boolean> => {
    try {
      const marketplaceAddress = getMarketplaceAddress()
      const characterContract = getErc721Contract(getCharacterAddress())
      console.log('account', account)
      console.log('getCharacterAddress()', getCharacterAddress())

      const isApprovalForAll = await characterContract.isApprovedForAll(account, marketplaceAddress)
      console.log('isApprovalForAll: ', isApprovalForAll)

      return isApprovalForAll
    } catch (e) {
      console.log('fetchApprovalNftMarket error', e)
    }
  },
)
