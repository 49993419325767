import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ButtonMarket from 'views/components/ButtonMarket'

function PlayGames(props) {
  return (
    <PlayGamesStyled>
      <img className="img__civilian" src="./images/market/civilian-dpt.png" alt="civilian" />
      <a href="https://app.diviner.finance/" target="_blank" rel="noopener noreferrer">
        <ButtonMarket img="./images/market/video-game.svg" name="PLAY" />
      </a>
    </PlayGamesStyled>
  )
}

const PlayGamesStyled = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  .img__civilian {
    padding-right: 20px;
  }
`
export default PlayGames
