import { IConfig } from './api.d'

const REACT_APP_API_URL = process.env.REACT_APP_RESTFUL_ENDPOINT || 'http://localhost/'
const REACT_APP_API_VERSION = process.env.REACT_APP_RESTFUL_VERSION || 'v1'

const config: IConfig = {
  API: {
    GET_CIVILIANS_MARKETPLACE: '/heros',
    GET_TRANSACTION_HISTORY: '/transactions',
  },
}

Object.keys(config.API).forEach((item) => {
  ;(config.API as any)[item] = `${REACT_APP_API_URL}${REACT_APP_API_VERSION}${config.API[item]}`
})

export default config
