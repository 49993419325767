import { createAction } from '@reduxjs/toolkit'

export const fetchingMarket = createAction<boolean | undefined>('marketplace/fetchingMarket')

export const fetchingInventory = createAction<boolean | undefined>('marketplace/fetchingInventory')

export const fetchingOnSale = createAction<boolean | undefined>('marketplace/fetchingOnSale')

export const increaseMarketPage = createAction('marketplace/increaseMarketPage')

export const updateMarketPage = createAction<number>('marketplace/updateMarketPage')

export const updateApproveToken = createAction<boolean | undefined>('marketplace/updateApproveToken')

export const updateIsApprovingDpt = createAction<boolean | undefined>('marketplace/updateIsApprovingDpt')

export const updateIsApprovingBusd = createAction<boolean | undefined>('marketplace/updateIsApprovingBusd')

export const updateIsApprovingNft = createAction<boolean | undefined>('marketplace/updateIsApprovingNft')

export const updateIsApprovedForAll = createAction<boolean | undefined>('marketplace/updateIsApprovedForAll')

export const cancelListing = createAction<any>('marketplace/cancelListing')

export const addListing = createAction<any>('marketplace/addListing')

export const purchaseListing = createAction<any>('marketplace/purchaseListing')
