import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { State } from './types'
import { fetchUserTokenData } from './user/actions'
import { getUserReboundSelector, setUserState } from './user/reducer'

export const useUserTokensInfo = () => {
  const userTokensInfo = useSelector((state: State) => state.user.userTokenData)
  return userTokensInfo
}

export const useFetchUser = (account: string) => {
  const isFirstRender = useRef(true)
  const dispatch = useAppDispatch()
  const rebound = useSelector(getUserReboundSelector)

  useEffect(() => {
    if (account) {
      dispatch(fetchUserTokenData(account))
      dispatch(setUserState({ rebound: false }))
    }
  }, [account])
  useEffect(() => {
    if (rebound && !isFirstRender.current) {
      if (account) {
        dispatch(fetchUserTokenData(account))
        dispatch(setUserState({ rebound: false }))
      }
    } else isFirstRender.current = false
  }, [account, rebound])
}
