import { Col, Row } from 'antd'
import { FILTER_OPTION, HERO_STATUS } from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { resetFilter, setPage, setMarketplaceState } from 'state/marketplace'
import { updateMarketPage } from 'state/marketplace/actions/actions'
import { fetchListCivilians } from 'state/marketplace/actions/marketActions'
import {
  filtersSelector,
  listedCharactersSelector,
  paginationSelector,
  reboundSelector,
  sortSelector,
} from 'state/marketplace/selectors/selector'
import styled from 'styled-components'
import { State } from '../state/types'
import Marketplace from './components/Civilian'
import Sidebar from './components/Sidebar'

function PageMarket(props) {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const history = useHistory()
  const pageNumber = params.get('page') || 1
  const { account } = useActiveWeb3React()
  const rebound = useSelector(reboundSelector)
  const listings = useSelector(listedCharactersSelector)
  const isFirstRender = useRef(true)
  const { limit, totalResults } = useSelector(paginationSelector)

  const filters = useSelector(filtersSelector)

  const sorts = useSelector(sortSelector)

  const isFetchingMarket = useSelector((state: State) => state.marketplace.fetching.market)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(resetFilter())
  }, [])

  useEffect(() => {
    filterData()

    // return () => {
    //   dispatch(resetFilter())
    // }
  }, [filters, sorts, pageNumber, account])
  useEffect(() => {
    if (rebound) {
      filterData()
    }
    // return () => changePageHandler(1)
  }, [rebound])

  const filterData = () => {
    let filter = {
      status: HERO_STATUS.ON_SALE,
      limit,
      page: pageNumber,
    }

    if (!isFirstRender.current) {
      const { isOwned, rangePrice, ...rest } = filters
      const updatedSort = Object.keys(sorts).reduce((accmu, key) => {
        // eslint-disable-next-line
        return (accmu += `${key}:${sorts[key] === 1 ? 'asc' : 'desc'},`)
      }, '')
      filter = Object.assign(
        filter,
        {
          ...filter,
          ...rest,
          price: (rangePrice?.from || rangePrice?.to) && `${rangePrice?.from || ''},${rangePrice?.to || ''}`,
        },
        {
          sortBy: updatedSort,
        },
      )
    } else {
      isFirstRender.current = false
    }
    dispatch(fetchListCivilians(Object.fromEntries(Object.entries(filter).filter(([_, v]) => !!v))))
  }

  const changePageHandler = (page) => {
    dispatch(updateMarketPage(page))
    history.push(`/?page=${page}`)

    // dispatch(fetchListingCivilians())
  }
  const filterOptions = [
    {
      type: FILTER_OPTION.SEARCH,
    },
    {
      label: 'Rarity',
      type: FILTER_OPTION.RARITY,
    },

    {
      label: 'Class',
      type: FILTER_OPTION.CLASS,
    },
    {
      label: 'Price',
      type: FILTER_OPTION.PRICE,
    },
    {
      label: 'Level',
      type: FILTER_OPTION.LEVEL,
    },
  ]
  const handleChangeFilter = (payload) => {
    dispatch(updateMarketPage(1))
    history.push(`/?page=1`)
    dispatch(setMarketplaceState(payload))
  }
  const marketplaceProps = {
    listings,
    isFetchingMarket,
    changePageHandler,
    limit,
    marketPage: +pageNumber,
    totalResults,
  }
  const sidebarProps = {
    filterOptions,
    handleChangeFilter,
  }

  return (
    <div className="marketPage">
      <PageMarketStyled>
        <Row gutter={[48, 16]}>
          <Col sm={24} md={24} lg={12} xl={6} className="pd-l80">
            {/* <SaleVolume /> */}
            <Sidebar {...sidebarProps} />
          </Col>
          <Col sm={24} md={24} lg={12} xl={18}>
            <Marketplace {...marketplaceProps} />
          </Col>
        </Row>
      </PageMarketStyled>
    </div>
  )
}
const PageMarketStyled = styled.div`
  padding: 0 15px;
  background: #15244e;

  @media (min-width: 769px) {
    padding: 0 60px;
  }
`
export default PageMarket
