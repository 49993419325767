import React from 'react'
import { Checkbox } from 'antd'
import styled from 'styled-components'
import gameCharacter from 'config/constants/game'
import { useSelector } from 'react-redux'
import { filtersSelector } from 'state/marketplace/selectors/selector'

const checkboxList = [
  {
    name: 'Chef',
  },
  {
    name: 'Farmer',
  },
  {
    name: 'Fireman',
  },
  {
    name: 'Officer',
  },
  {
    name: 'Painter',
  },
  {
    name: 'Doctor',
  },
  {
    name: 'Football Player ',
  },
  {
    name: 'Magician',
  },
  {
    name: 'Police',
  },
  {
    name: 'Singer',
  },
]

function ClassFilter({ handleChangeFilter }) {
  const filters = useSelector(filtersSelector)
  const flatCheckbox = gameCharacter
    .reduce((accmu, item, idx) => {
      return [
        ...accmu,
        ...item.map((subItem, subIdx) => ({
          ...subItem,
          heroRarity: idx,
          heroName: subIdx,
        })),
      ]
    }, [])
    ?.sort((a, b) => a.name.localeCompare(b.name))
  const handleClickCheckbox = (item) => () => {
    let updatedFilters = { ...filters }
    const index = (filters?.classes && filters?.classes?.findIndex((heroName) => heroName === item.name)) ?? -1
    if (index > -1) {
      updatedFilters.classes = updatedFilters.classes.filter((heroName, idx) => idx !== index)
    } else {
      updatedFilters = {
        ...filters,
        classes: [...(filters.classes || []), item.name],
      }
    }
    handleChangeFilter({
      filters: updatedFilters,
    })
  }
  return (
    <ClassFilterStyled>
      <div className="classFilter">
        <div className="Checkbox">
          {flatCheckbox.map(
            (cb, index) =>
              index <= 4 && (
                <div className="classCheckbox" key={index}>
                  <Checkbox
                    checked={filters.classes && filters.classes.some((item) => item === cb.name)}
                    onClick={handleClickCheckbox(cb)}
                  >
                    <p>{cb.name}</p>
                  </Checkbox>
                </div>
              ),
          )}
        </div>
        <div className="Checkbox">
          {flatCheckbox.map(
            (cb, index) =>
              index >= 5 && (
                <div className="classCheckbox" key={index}>
                  <Checkbox
                    checked={filters.classes && filters.classes.some((item) => item === cb.name)}
                    onClick={handleClickCheckbox(cb)}
                  >
                    <p>{cb.name}</p>
                  </Checkbox>
                </div>
              ),
          )}
        </div>
      </div>
    </ClassFilterStyled>
  )
}

const ClassFilterStyled = styled.div`
  .classFilter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .classCheckbox {
      padding-bottom: 20px;
      span.ant-checkbox.ant-checkbox-checked {
        &::after {
          opacity: 0;
          border: unset;
        }
      }
      span {
        padding: 0px;
      }
    }

    .classCheckbox:nth-child(5) {
      padding-bottom: 0px;
    }

    p {
      color: #fff;
      font-size: 16px;
      font-weight: 300;
      margin: 0;
    }
  }
`
export default ClassFilter
