import { DPT_ADDRESS } from 'config'
import { getDptAddress, getBusdAddress } from 'utils/addressHelpers'
import { GameCharacterConfig, EnemyConfig } from './types'

export const gameCharacter: GameCharacterConfig[][] = [
  [
    {
      name: 'Magician',
      chestImg: 'images/buy-chest/civilian/magician.png',
      img: '/images/market/magician.png',
      marketImg: 'images/marketv2/jobs/200x200/magician.png',
      detailImg: 'images/marketv2/jobs/detail/magician.png',
    },
    {
      name: 'Officer',
      chestImg: 'images/buy-chest/civilian/officer.png',
      img: '/images/market/officer.png',
      marketImg: 'images/marketv2/jobs/200x200/officer.png',
      detailImg: 'images/marketv2/jobs/detail/officer.png',
    },
  ],

  [
    {
      name: 'Police',
      chestImg: 'images/buy-chest/civilian/police.png',
      img: '/images/market/police.png',
      marketImg: 'images/marketv2/jobs/200x200/police.png',
      detailImg: 'images/marketv2/jobs/detail/police.png',
    },
    {
      name: 'Painter',
      chestImg: 'images/buy-chest/civilian/painter.png',
      img: '/images/market/painter.png',
      marketImg: 'images/marketv2/jobs/200x200/painter.png',
      detailImg: 'images/marketv2/jobs/detail/painter.png',
    },
  ],
  [
    {
      name: 'Football Player',
      chestImg: 'images/buy-chest/civilian/football-player.png',
      img: '/images/market/football-player.png',
      marketImg: 'images/marketv2/jobs/200x200/football-player.png',
      detailImg: 'images/marketv2/jobs/detail/football-player.png',
    },
    {
      name: 'Singer',
      chestImg: 'images/buy-chest/civilian/singer.png',
      img: '/images/market/singer.png',
      marketImg: 'images/marketv2/jobs/200x200/singer.png',
      detailImg: 'images/marketv2/jobs/detail/singer.png',
    },
  ],
  [
    {
      name: 'Fireman',
      chestImg: 'images/buy-chest/civilian/fireman.png',
      img: '/images/market/fireman.png',
      marketImg: 'images/marketv2/jobs/200x200/fireman.png',
      detailImg: 'images/marketv2/jobs/detail/fireman.png',
    },
    {
      name: 'Farmer',
      chestImg: 'images/buy-chest/civilian/farmer.png',
      img: '/images/market/farmer.png',
      marketImg: 'images/marketv2/jobs/200x200/farmer.png',
      detailImg: 'images/marketv2/jobs/detail/farmer.png',
    },
  ],
  
  [
    {
      name: 'Chef',
      chestImg: 'images/buy-chest/civilian/chef.png',
      img: '/images/market/chef.png',
      marketImg: 'images/marketv2/jobs/200x200/chef.png',
      detailImg: 'images/marketv2/jobs/detail/chef.png',
    },
    {
      name: 'Doctor',
      chestImg: 'images/buy-chest/civilian/doctor.png',
      img: '/images/market/doctor.png',
      marketImg: 'images/marketv2/jobs/200x200/doctor.png',
      detailImg: 'images/marketv2/jobs/detail/doctor.png',
    },
  ],
]
export const tokenImg = {
  [getDptAddress()]: 'dpt.svg',
  [getBusdAddress()]: 'busd.svg',
  '0x0000000000000000000000000000000000000000': 'bnb.png',
}

export const tokenSymbol = {
  [getDptAddress()]: 'DPT',
  [getBusdAddress()]: 'BUSD',
  '0x0000000000000000000000000000000000000000': 'BNB',
}

export const enemiesConfig: EnemyConfig[] = [
  { name: 'Iron Tail', img: './images/pve/monster/beast.gif' },
  { name: 'Blade Mantis', img: './images/pve/monster/beast-1.gif' },
  { name: 'Putrid Snail', img: './images/pve/monster/beast-2.gif' },
  { name: 'Mad Rat', img: './images/pve/monster/beast-3.gif' },
  { name: 'Hooded Death', img: './images/pve/monster/beast-4.gif' },
  { name: 'Poison Stella', img: './images/pve/monster/beast-5.gif' },
  { name: 'Bully Croc', img: './images/pve/monster/beast-6.gif' },
  { name: 'Fox Beastman', img: './images/pve/monster/beast-7.gif' },
  { name: 'Cow Blower', img: './images/pve/monster/beast-8.gif' },
  { name: 'Baroda Maestro', img: './images/pve/monster/beast-9.gif' },
]

export const upgradeChance = [0, 0, 0, 0, 0, 0, 0, 0]

export default gameCharacter
