import { useWeb3React } from '@web3-react/core'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { useAppDispatch } from 'state'
import { onSaleCharactersSelector, ownedInventoryCharactersSelector } from 'state/marketplace/selectors/selector'
import { State } from 'state/types'
import { ListCardContainerStyled } from '../Civilian'
import CardSkeleton from '../Civilian/components/CardSkeleton'
import CiviliansListing from '../Civilian/ItemContainer'
import PaginationMarket from '../PaginationMarket'

const Civilian = ({ listings, changePageHandler, limit, marketPage, totalResults, status }) => {
  const { account } = useWeb3React()
  const isFetchingInventory = useSelector((state: State) => state.marketplace.fetching.market)
  const paginationMarketProps = {
    changePageHandler,
    limit,
    marketPage,
    totalResults,
  }

  // useEffect(() => {
  //   if (account && isEmpty(civilians)) {
  //     dispatch(fetchInventoryCivilians(account))
  //   }
  // }, [account])

  return (
    <>
      <ListCardContainerStyled>
        {account && isFetchingInventory ? (
          [...Array(4)].map(() => <CardSkeleton />)
        ) : (
          <CiviliansListing listings={listings} status={status} />
        )}
      </ListCardContainerStyled>
      {!isFetchingInventory && <PaginationMarket {...paginationMarketProps} />}
    </>
  )
}

export default Civilian
