import React from 'react'
import styled, { css } from 'styled-components'
import CivilianModal from './CivilianModal'
function MysteryModal({ animationDL, numberArr }) {
  return (
    <MysteryModalStyled>
      <video autoPlay muted playsInline style={{ width: '100vw', height: '100vh', objectFit: 'cover' }}>
        <source src="/images/marketv2/box-open.mp4" type="video/webm" style={{ position: 'absolute' }} />
        <source src="/images/marketv2/box-open.org" type="video/org" style={{ position: 'absolute' }} />
      </video>
      <div className="mystery__civilian">
        {[...Array(numberArr)].map((civilian, index) => (
          <CivilianModal index={index * 3} animationDL={animationDL} />
        ))}
      </div>
    </MysteryModalStyled>
  )
}

const MysteryModalStyled = styled.div`
  position: relative;

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes rotateCivilian {
    0% {
      opacity: 0;
      transform: rotate(60deg);
    }
    50% {
      opacity: 1;
      transform: rotate(0deg);
    }
    100% {
      opacity: 0;
      transform: rotate(-60deg);
    }
  }

  @keyframes scaleCivilian {
    0% {
      opacity: 0;
      transform: scale(0.2);
    }
    50% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.2);
    }
  }

  /* .mystery__civilian {
    height: auto;
    max-height: 750px;
    overflow-x: scroll;
    @media only screen and (max-width: 600px) {
      height: 450px;
    }
    @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media only screen and (max-width: 500px) {
        grid-template-columns: repeat(2, 1fr);
      }
  } */

  .civilian-style {
    animation: fadeInUp 4s ease-in-out 0s forwards;
    position: absolute;
    top: 20%;
    left: 40%;
    transform: translate(-50%, -50%);
    opacity: 0;
    @media (max-width: 1200px) {
      top: 40%;
    }
    .civilian__scale {
      animation: scaleCivilian 4s ease-in-out 0s forwards;
      .civilian {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        width: 500px;
        height: 500px;
        animation: rotateCivilian 4s ease-in-out 0s forwards;
        font-size: 3.75rem;
        @media (max-width: 1200px) {
          width: 350px;
          height: 350px;
          font-size: 32px;
        }

        @media (max-width: 600px) {
          width: 250px;
          height: 250px;
          font-size: 20px;
        }

        .civilian__img {
          max-width: unset;
          width: 100%;
          img {
            width: 100%;
          }
        }
        .civilian__star {
          img {
            width: 60px;
            height: 60px;
            @media (max-width: 1200px) {
              width: 35px;
              height: 35px;
            }
            @media (max-width: 600px) {
              width: 20px;
              height: 20px;
            }
          }
        }
        .civilian__lv-number {
          img {
            width: 80px;
            height: 80px;
            @media (max-width: 1200px) {
              width: 55px;
              height: 55px;
            }
            @media (max-width: 600px) {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
`
export default MysteryModal
