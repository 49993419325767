import { FILTER_OPTION } from 'constants/index'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import ClassFilter from './components/ClassFilter'
import LevelFilter from './components/LevelFilter'
import PriceFilter from './components/PriceFilter'
import RarityFilter from './components/RarityFilter'
import RatingsFilter from './components/RatingsFilter'
import SearchFilter from './components/SearchFilter'

export interface IFilterOption {
  label?: string
  type: string
}
export interface ISidebarProps {
  filterOptions: IFilterOption[]
  handleChangeFilter: (payload: any) => void
}
const { CLASS, LEVEL, PRICE, RARITY, RATINGS, SEARCH } = FILTER_OPTION
const Sidebar: React.FC<ISidebarProps> = ({ filterOptions, handleChangeFilter }) => {
  const FilterComponent = useMemo(
    () => ({
      [CLASS]: ClassFilter,
      [PRICE]: PriceFilter,
      [RARITY]: RatingsFilter,
      [RATINGS]: RarityFilter,
      [SEARCH]: SearchFilter,
      [LEVEL]: LevelFilter,
    }),
    [],
  )
  const renderFilters = (options: IFilterOption[]) => {
    return (
      options &&
      options?.map(({ type, label }, idx) => {
        const Component = FilterComponent[type]
        const props = {
          handleChangeFilter,
        }
        return (
          <div key={`${type}-${idx}`} className="borderBT__sidebar">
            {label && <h3>{label}</h3>}
            <Component {...props} />
          </div>
        )
      })
    )
  }
  const handleResetFilter = () => {
    handleChangeFilter({
      filters: {},
    })
  }
  return (
    <SidebarStyled>
      <div className="background__sidebar">
        <div className="filter">
          <h3 className="filter--font-24">Filter</h3>
          <h3 className="filter__clear" onClick={handleResetFilter}>
            Clear Filter
          </h3>
        </div>
        {renderFilters(filterOptions)}
      </div>
    </SidebarStyled>
  )
}

const SidebarStyled = styled.div`
  .background__sidebar {
    background: linear-gradient(
      180deg,
      rgba(35, 64, 145, 0) 0%,
      #182e68 13.02%,
      #234091 85.94%,
      rgba(35, 64, 145, 0) 100%
    );
    padding: 36px 20px 40px;
    margin-left: 0;
    max-width: 100%;

    @media (min-width: 769px) {
      max-width: 487px;
      margin-left: -60px;
      padding: 36px 32px 40px 60px;
    }

    h3 {
      font-size: 1.3rem;
      color: #7cc8ff;
    }
    .filter__clear {
      cursor: pointer;
    }

    .filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-size: 20px;
        font-weight: 800;
        margin-bottom: 1rem;
        color: #7cc8ff;

        &:first-of-type {
          color: #ffffff;
        }

        &.filter--font-24 {
          font-size: 1.5rem;
        }
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: #3786ff;
      }
    }

    .ant-checkbox-inner {
      background: #011446;
      border: unset;
      margin-right: 15px;
    }

    .borderBT__sidebar {
      border-bottom: 1px solid #376bb9;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .borderBT__level {
    padding: 30px 0px 40px 0px;
  }
`
export default Sidebar
