import { createSlice } from '@reduxjs/toolkit'
import {
  updateTxHash,
  addTransaction,
  clearTransaction,
  errorTransaction,
  finalizeTransaction,
  clearTransactionNotConfirmed,
} from './actions'

export enum TransactionStatus {
  pending = 'pending',
  success = 'success',
  error = 'error',
}
export interface TransactionDetails {
  message?: string
  status: TransactionStatus
  txHash?: string
}

export interface TransactionState {
  [callData: string]: TransactionDetails
}

export const initialState: TransactionState = {}

export const transactionsSlicer = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTransaction, (transactions, action) => {
        if (transactions[action.payload.callData]) return
        transactions[action.payload.callData] = {
          status: TransactionStatus.pending,
          message: '',
        }
      })
      .addCase(clearTransaction, (transactions, { payload: { callData } }) => {
        if (!transactions[callData] || !transactions[callData].txHash) return
        transactions[callData] = null
      })
      .addCase(clearTransactionNotConfirmed, (transactions, { payload: { callData } }) => {
        if (!transactions[callData]) return

        if (transactions[callData].txHash) return

        transactions[callData] = null
      })
      .addCase(updateTxHash, (transactions, { payload: { callData, txHash = '' } }) => {
        if (!transactions[callData] || transactions[callData].txHash) return

        transactions[callData].txHash = txHash
      })
      .addCase(finalizeTransaction, (transactions, { payload: { callData, message, txHash = '' } }) => {
        if (!transactions[callData]) {
          return
        }
        transactions[callData].status = TransactionStatus.success
        transactions[callData].message = message
        transactions[callData].txHash = txHash
      })
      .addCase(errorTransaction, (transactions, { payload: { callData, message, txHash = '' } }) => {
        if (!transactions[callData]) return
        transactions[callData].status = TransactionStatus.error
        transactions[callData].message = message
        transactions[callData].txHash = txHash
      })
  },
})

export default transactionsSlicer.reducer
