import { useWeb3React } from '@web3-react/core'
import { utils } from 'ethers'
import LoadingFullScreen from 'components/Common/LoadingFullScreen'
import GlobalModal from 'components/static/GlobalModal'

import { useActiveWeb3React } from 'hooks'
import useRefresh from 'hooks/useRefresh'
import useWeb3ReactManager from 'hooks/useWeb3ReactManager'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { useFetchUser, useUserTokensInfo } from 'state/hook'
import { fetchApprovalNftMarket } from 'state/marketplace/actions/tokenActions'
import styled from 'styled-components'
import MysteryBox from 'views/MysteryBox'
import PageDetail from 'views/PageDetail'
import PageHistory from 'views/PageHistory'
import PageInventory from 'views/PageInventory'
import PageMarket from 'views/PageMarket'
import Header from 'views/Header'
import { fetchPrices } from 'state/price'
import { useSelector } from 'react-redux'
import { errorMessageSelector, loadedSelector } from 'state/marketplace/selectors/selector'

import Popups from './components/Popups'
import './index.scss'

function fakeRequest() {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), 1500))
}

function PrivateRoute({ children, isAuthenticated, isLoading, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const App: React.FC = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  const userTokensInfo = useUserTokensInfo()
  const error = useSelector(errorMessageSelector)
  const { account, chainId } = useWeb3React()
  const [isLoading, setLoading] = useState(true)
  const loaded = useSelector(loadedSelector)
  useWeb3ReactManager()

  useEffect(() => {
    console.warn = () => null
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (!account) setLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    if (chainId) {
      window.localStorage.setItem('chainId', chainId.toString())
    }
  }, [chainId])

  useEffect(() => {
    if (loaded) setLoading(false)
  }, [loaded])
  useFetchUser(account)

  useEffect(() => {
    dispatch(fetchPrices())
    if (account) {
      dispatch(fetchApprovalNftMarket(account))
    }
  }, [account])

  return (
    <HashRouter>
      <Popups />
      <Header />
      {isLoading ? (
        <LoadingFullScreen />
      ) : (
        <FullScreenStyled>
          <Switch>
            <Route path="/" exact>
              <PageMarket />
            </Route>

            <Route path="/detail/:id">
              <PageDetail />
            </Route>
            <Route path="/mystery-box" exact>
              <MysteryBox />
            </Route>
            <PrivateRoute path="/inventory" isAuthenticated={account} isLoading={isLoading}>
              <PageInventory />
            </PrivateRoute>
            <PrivateRoute path="/history" isAuthenticated={account} isLoading={isLoading}>
              <PageHistory />
            </PrivateRoute>
          </Switch>

          <GlobalModal />
        </FullScreenStyled>
      )}
    </HashRouter>
  )
}

const FullScreenStyled = styled.div`
  width: 100%;
  height: 100%;
  /* overflow-x: hidden; */

  .marketPage {
    padding: 134px 0px 80px 0px;
    height: 100%;
  }
`
export default React.memo(App)
