import React from 'react'
import styled from 'styled-components'

const TabsContentStyled = styled.div`
  .tabsBox {
    display: flex;
    align-items: center;
    margin: 60px 0 60px;
    border-bottom: 1px solid #376bb9;
  }

  .tabs__civilian {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #8a91a6;
    transform: ease all 0.5s;
    padding-bottom: 20px;
    border-bottom: 6px solid transparent;
    transition: ease all 0.5s;
    margin-right: 50px;

    &:hover {
      color: #fff;
      border-bottom: 6px solid #55b3f8;
    }

    p {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
    }

    img {
      width: 40px;
    }

    .tabs__content {
      display: flex;
      justify-content: left;
      align-items: center;

      img {
        margin-right: 4px;
      }
    }
  }

  .active {
    color: #fff;
    border-bottom: 6px solid #55b3f8;
  }
`

function TabsCivilian({ tabList, currentTab, setTab }) {
  return (
    <TabsCivilianStyled>
      <TabsContentStyled>
        <div className="tabsBox">
          {tabList.map((tab) => (
            <div
              className={currentTab === tab.name ? 'tabs__civilian active' : 'tabs__civilian'}
              onClick={() => setTab(tab.name)}
            >
              <div className="tabs__content">
                <img src={tab.img} alt="mascot.png" />
                <p>{tab.name}</p>
              </div>
            </div>
          ))}
        </div>

        {/* <div className="content__tabs">{children}</div> */}
      </TabsContentStyled>
    </TabsCivilianStyled>
  )
}

const TabsCivilianStyled = styled.div``
export default TabsCivilian
