import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isEmpty, mapKeys, omit } from 'lodash'
import { MarketplaceState } from 'state/marketplace/types/types'
import {
  addListing,
  cancelListing,
  fetchingInventory,
  fetchingMarket,
  fetchingOnSale,
  increaseMarketPage,
  purchaseListing,
  updateApproveToken,
  updateIsApprovedForAll,
  updateMarketPage,
} from './actions/actions'
import { fetchOnSaleCivilians } from './actions/inventoryActions'
import {
  fetchAmountCiviliansListing,
  fetchBuyerFee,
  fetchDetailCivilian,
  fetchListCivilians,
  fetchListingCivilians,
  fetchSellerFee,
} from './actions/marketActions'
import { fetchApprovalNftMarket } from './actions/tokenActions'

const initialState: MarketplaceState = {
  civilian: {},
  listing: [],
  inventoryCharacters: {},
  onSaleCivilians: {},
  pagination: {
    marketPage: 1,
    limit: 24,
    totalResults: 0,
  },
  sorts: {
    // byPrice: 1,
    // byLevel: 1,
    // byRarity: 1,
  },
  filters: {
    // isOwned: false,
    // rarity: 0,
    // level: [],
    // rangePrice: {
    //   from: 0,
    //   to: 0,
    // },
  },
  fetching: {
    market: true,
    inventory: true,
    detail: true,
    onSale: true,
    button: false,
  },
  approving: {
    dpt: false,
    busd: false,
    nft: false,
  },
  user: {
    dpt: {
      balance: '0',
      allowance: '0',
      isAllowance: false,
    },
    busd: {
      balance: '0',
      allowance: '0',
      isAllowance: false,
    },
    bnb: {
      balance: '0',
    },
  },
  fee: {
    sellerFee: '0',
    buyerFee: '0',
  },
  isApprovedForAll: false,
  amount: {
    marketplace: 0,
    inventory: 0,
  },
  rebound: true,
  error: '',
  loaded: false,
}

export const marketplaceSlicer = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    sortByPrice: (state, action) => {
      state.sorts.price = action.payload
    },
    // sortByLevel: (state, action) => {
    //   state.sorts.byLevel = action.payload
    // },
    // sortByRarity: (state, action) => {
    //   state.sorts.byRarity = action.payload
    // },
    filterByRank: (state, action) => {
      state.filters.heroRarity = action.payload
    },
    filterByLevel: (state, action) => {
      state.filters.level = action.payload
    },
    filterByPriceFrom: (state, action) => {
      state.filters.rangePrice.from = action.payload
    },
    filterByPriceTo: (state, action) => {
      state.filters.rangePrice.to = action.payload
    },

    setPage: (state, action) => {
      state.pagination.marketPage = action.payload
    },
    filterByOwnership: (state, action) => {
      state.filters.isOwned = action.payload
    },
    resetFilter: (state) => {
      state.filters = {}
    },
    resetState: (state) => {
      state.listing = {}
      state.inventoryCharacters = {}
      state.onSaleCivilians = {}

      state.pagination = {
        marketPage: 1,
        limit: 8,
        totalResults: 0,
      }
      state.sorts = {
        // byPrice: 1,
        // byLevel: 1,
        // byRarity: 0,
      }
      state.filters = {}
      state.fetching = {
        market: false,
        inventory: false,
        detail: false,
        onSale: false,
        button: false,
      }
      state.user = {
        dpt: {
          balance: '0',
          allowance: '0',
          isAllowance: false,
        },
        busd: {
          balance: '0',
          allowance: '0',
          isAllowance: false,
        },
        bnb: {
          balance: '0',
        },
      }
      state.fee = {
        sellerFee: '',
        buyerFee: '',
      }

      state.approving = {
        dpt: false,
        busd: false,
        nft: false,
      }

      state.isApprovedForAll = false

      state.amount = {
        inventory: 0,
        marketplace: 0,
      }
    },
    setMarketplaceState: (state, action) => {
      Object.keys(action?.payload || {}).forEach((key) => {
        state[key] = action.payload[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchingMarket, (state, action: PayloadAction<boolean>) => {
        state.fetching.market = action.payload
      })
      .addCase(fetchingInventory, (state, action: PayloadAction<boolean>) => {
        state.fetching.inventory = action.payload
      })
      .addCase(fetchingOnSale, (state, action: PayloadAction<boolean>) => {
        state.fetching.onSale = action.payload
      })
      .addCase(increaseMarketPage, (state) => {
        state.pagination.marketPage += 1
      })
      .addCase(updateMarketPage, (state, action) => {
        state.pagination.marketPage = action.payload
      })
      .addCase(fetchApprovalNftMarket.fulfilled, (state, action) => {
        state.isApprovedForAll = action.payload
        state.loaded = true
      })

      .addCase(fetchBuyerFee.fulfilled, (state, action) => {
        state.fee.buyerFee = action.payload
      })
      .addCase(fetchSellerFee.fulfilled, (state, action) => {
        state.fee.sellerFee = action.payload
      })
      .addCase(updateApproveToken, (state, action) => {
        state.user.dpt.isAllowance = action.payload
      })

      .addCase(fetchListingCivilians.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.listing = { ...mapKeys(action.payload, '_id') }
      })
      // .addCase(fetchInventoryCivilians.fulfilled, (state, action) => {
      //   if (isEmpty(action.payload)) return
      //   state.inventoryCharacters = { ...state.inventoryCharacters, ...mapKeys(action.payload, '_id') }
      // })
      .addCase(fetchOnSaleCivilians.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return

        state.onSaleCivilians = { ...state.onSaleCivilians, ...mapKeys(action.payload, '_id') }
      })
      .addCase(addListing, (state, action) => {
        if (isEmpty(action.payload)) return

        const { tokenId, listingNft } = action.payload

        state.inventoryCharacters = omit(state.inventoryCharacters, [`${listingNft}_${tokenId}`])
        state.onSaleCivilians = {
          ...state.onSaleCivilians,
          [`${listingNft}_${tokenId}`]: action.payload,
        }
        state.listing = { ...state.listing, [`${listingNft}_${tokenId}`]: action.payload }
      })
      .addCase(cancelListing, (state, action) => {
        // if (isEmpty(action.payload)) return
        // const { nftAddress, tokenId } = action.payload
        // state.onSaleCivilians = omit(state.onSaleCivilians, [`${nftAddress}_${tokenId}`])
        // state.listing = omit(state.listing, [`${nftAddress}_${tokenId}`])
        // state.inventoryCharacters = {
        //   ...state.inventoryCharacters,
        //   [`${nftAddress}_${tokenId}`]: action.payload,
        // }
        state.rebound = true
      })
      .addCase(purchaseListing, (state, action) => {
        if (isEmpty(action.payload)) return
        const { nftAddress, tokenId } = action.payload
        state.inventoryCharacters = {
          ...state.inventoryCharacters,
          [`${nftAddress}_${tokenId}`]: action.payload,
        }
        state.listing = omit(state.listing, [`${nftAddress}_${tokenId}`])
      })
      .addCase(updateIsApprovedForAll, (state, action) => {
        state.isApprovedForAll = action.payload
      })
      .addCase(fetchAmountCiviliansListing.fulfilled, (state, action) => {
        state.amount.marketplace = action.payload
      })

      // .addCase(fetchListCivilians.pending, (state, action) => {})
      .addCase(fetchListCivilians.fulfilled, (state, action) => {
        state.listing = action.payload.results
        state.pagination.totalResults = action.payload.totalResults
        state.rebound = false
      })

      .addCase(fetchDetailCivilian.pending, (state) => {
        state.fetching.detail = true
      })
      .addCase(fetchDetailCivilian.fulfilled, (state, action) => {
        state.civilian = action.payload
        state.fetching.detail = false
      })
  },
})

export const {
  sortByPrice,
  // sortByLevel,
  setPage,
  filterByRank,
  filterByLevel,
  filterByPriceFrom,
  filterByPriceTo,
  filterByOwnership,
  resetState,
  resetFilter,
  setMarketplaceState,
} = marketplaceSlicer.actions

export default marketplaceSlicer.reducer
