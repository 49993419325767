const CloseButtonSVG = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 70 70" fill="none" {...props}>
    <path
      d="M69.7957 35.5908C69.7957 54.5805 54.1676 70 34.8979 70C15.6282 70 0 54.5805 0 35.5908C0 33.3658 1.01351 30.3647 1.01351 30.3647L68.7862 29.6533C68.7862 29.6533 69.7957 33.7798 69.7957 35.5908Z"
      fill="#AF460B"
    />
    <path
      d="M34.8979 66.3779C54.1714 66.3779 69.7957 51.5516 69.7957 33.2623C69.7957 14.973 54.1714 0.146667 34.8979 0.146667C15.6243 0.146667 0 14.973 0 33.2623C0 51.5516 15.6243 66.3779 34.8979 66.3779Z"
      fill="#E47638"
    />
    <path
      d="M35.4057 66.1278C54.5117 66.1278 70.0001 51.3246 70.0001 33.0639C70.0001 14.8032 54.5117 0 35.4057 0C16.2997 0 0.811279 14.8032 0.811279 33.0639C0.811279 51.3246 16.2997 66.1278 35.4057 66.1278Z"
      fill="#E47638"
    />
    <path
      d="M32.5 60C48.7924 60 62 47.2401 62 31.5C62 15.7599 48.7924 3 32.5 3C16.2076 3 3 15.7599 3 31.5C3 47.2401 16.2076 60 32.5 60Z"
      fill="#F28548"
    />
    <g opacity="0.85">
      <path
        opacity="0.85"
        d="M5.61583 26.1217C5.61583 26.1217 6.82967 26.7426 8.65043 24.8799C10.4712 23.0171 12.2919 18.7224 17.6531 11.9441C17.6531 11.8923 17.7036 11.8923 17.7036 11.8406C18.8669 10.6505 19.0186 8.06334 16.2369 9.77087C14.669 10.754 12.646 12.358 10.4206 14.9452C5.61583 20.5852 4.45257 25.5008 5.61583 26.1217Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.85"
      d="M21.7497 7.23558C21.3451 7.8565 22.0026 8.63266 22.7107 8.37394C23.874 7.95999 25.8465 7.23559 26.6557 6.82164C27.1109 6.35596 26.9086 5.26935 25.7959 5.26935C24.5315 5.32109 22.2555 6.40769 21.7497 7.23558Z"
      fill="white"
    />
    <rect x="19" y="26.0711" width="10" height="36" rx="5" transform="rotate(-45 19 26.0711)" fill="#FFF2F2" />
    <rect x="26.071" y="51.5269" width="10" height="36" rx="5" transform="rotate(-135 26.071 51.5269)" fill="#FFF2F2" />
    <path
      d="M23.1093 27.6768C22.9184 27.3223 22.7906 26.9531 22.7049 26.6075C22.6192 26.2618 22.6175 25.8634 22.637 25.5266C22.697 24.8148 22.9669 24.0211 23.5959 23.5761C23.7846 23.4326 24.0371 23.4737 24.2063 23.6671C24.3119 23.7755 24.3545 23.8985 24.3341 24.0362L24.3132 24.0742C24.2515 24.3876 24.1685 24.6395 24.1276 24.9149C24.0868 25.1902 24.0459 25.4655 24.0473 25.7643C24.0064 26.0396 24.0077 26.3384 24.0091 26.6372C24.0104 26.9359 23.9908 27.2728 23.95 27.5481L23.9291 27.5862C23.9092 27.8234 23.6783 27.9435 23.4258 27.9024C23.3206 27.8936 23.2149 27.7852 23.1093 27.6768Z"
      fill="white"
    />
    <path
      d="M42.3396 22.6175C42.6745 22.394 43.0299 22.2319 43.3659 22.114C43.702 21.9961 44.0984 21.9568 44.4356 21.9444C45.1498 21.937 45.9655 22.1308 46.4679 22.715C46.6285 22.8893 46.6114 23.1446 46.4349 23.3312C46.3369 23.4466 46.2185 23.5006 46.0795 23.4933L46.0396 23.4761C45.7218 23.4443 45.4632 23.3854 45.1852 23.3707C44.9073 23.356 44.6293 23.3413 44.332 23.3708C44.054 23.3561 43.7567 23.3856 43.4594 23.4151C43.1621 23.4447 42.8249 23.457 42.547 23.4423L42.5071 23.4251C42.269 23.4276 42.1277 23.2091 42.1448 22.9538C42.1437 22.8483 42.2416 22.7329 42.3396 22.6175Z"
      fill="white"
    />
  </svg>
)

export default CloseButtonSVG
