import { Button, Input, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ButtonMarket from 'views/components/ButtonMarket'

function BuyMystery({onClick,className,transactionTime}) {
  const [input, setInput] = useState(0)
  const onChangeInput = (value) => setInput(value)
  return (
    <BuyMysteryStyled className={className} style={{animationDelay: `${transactionTime}s`}}>
      <div className="buy__mystery">
        <div className="buy__mystery__content">
          <div className="buy__mystery__content-star">
            <div className="icon-star">
              <div className="icon-star__img">
                <img src="/images/market/star.svg" alt="star" />
              </div>
              <span>60%</span>
            </div>
            <div className="icon-star">
              <div className="icon-star__img">
                {[...Array(2)].map(() => (
                  <img src="/images/market/star.svg" alt="star" />
                ))}
              </div>
              <span>40%</span>
            </div>
            <div className="icon-star">
              <div className="icon-star__img">
                {[...Array(3)].map(() => (
                  <img src="/images/market/star.svg" alt="star" />
                ))}
              </div>
              <span>25%</span>
            </div>
            <div className="icon-star">
              <div className="icon-star__img">
                {[...Array(4)].map(() => (
                  <img src="/images/market/star.svg" alt="star" />
                ))}
              </div>
              <span>5%</span>
            </div>
            <div className="icon-star">
              <div className="icon-star__img">
                {[...Array(5)].map(() => (
                  <img src="/images/market/star.svg" alt="star" />
                ))}
              </div>

              <span>1%</span>
            </div>
          </div>

          <div className="buy__mystery__content-input">
            <Button className="button__arrow" onClick={() => input > 0 && setInput(input - 1)}>
              <img src="./images/marketv2/arrow-left.svg" alt="arrow-right" />
            </Button>

            <InputNumber
              className="input__buy-chest"
              min={1}
              max={50}
              value={input}
              onChange={onChangeInput}
              controls={false}
            />

            <Button className="button__arrow" onClick={() => input < 50 && setInput(input + 1)}>
              <img src="./images/marketv2/arrow-right.svg" alt="arrow-right" />
            </Button>
          </div>
          <ButtonMarket name="50" img="/images/market/busd.svg" onClick={onClick}/>
        </div>
      </div>
    </BuyMysteryStyled>
  )
}

const BuyMysteryStyled = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0px);

  .buy__mystery {
    background: #010d81;
    border-radius: 10px;
    border: solid #010d81 12px;

    &__content {
      padding: 34px 28px 28px 28px;
      border: solid #865bff 1px;
      background: linear-gradient(125.52deg, #202da7 8.85%, #3f1698 89.95%);
      border-radius: 10px;
      @media (max-width: 500px) {
        padding: 15px 10px 10px 10px;
      }
      &-star {
        border-bottom: solid 1px #1561a8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 28px;
        @media (max-width: 500px) {
          padding-top: 10px;
        }
        .icon-star {
          padding-right: 30px;
          display: flex;
          justify-content: left;
          align-items: center;
          color: #fff;
          font-size: 18px;
          font-weight: 800;
          @media (max-width: 500px) {
            padding-right: 20px;
          }
          &__img {
            margin-right: 7px;
            display: flex;
            text-align: left;
            align-items: center;
            img {
              margin-left: 5px;
              width: 17px;
              @media (max-width: 500px) {
                display: none;
              }
            }
          }
        }
        .icon-star:last-child {
          padding-right: 0px;
        }
      }

      &-input {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .button-market {
        margin: 0 auto;
      }

      .input__buy-chest {
        margin: 40px 12px;
        border: solid 1px #fff;
        box-shadow: inset 0px 2px 4px rgb(0 0 0 / 40%);
        padding: 6px 0px;
        @media (max-width: 500px) {
          margin: 20px 0px;
        }
        .ant-input-number-input {
          text-align: center;
        }
      }

      .button__arrow {
        border: unset;
        background: unset;
        height: 100%;
        transition: ease all 0.5s;
        &:hover {
          transform: scale(1.1);
        }
        &::after {
          opacity: 0;
        }
      }

      .ant-form-item {
        margin-bottom: 0px;
      }
    }
  }
`
export default BuyMystery
