import { createAsyncThunk } from '@reduxjs/toolkit'
import { createClient, gql } from '@urql/core'
import API from 'api'
import config from 'api/config'
import characterAbi from 'config/abi/character.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import gameCharacter from 'config/constants/game'
import { BigNumber, ethers, Overrides, utils } from 'ethers'
import { groupBy, keyBy, map, merge, values as _values } from 'lodash'
import { fetchingMarket, updateMarketPage } from 'state/marketplace/actions/actions'
import { Listing } from 'state/marketplace/types/types'
import { _getCiviliansByIds } from 'state/marketplace/utils'
import { State } from 'state/types'
import { getCharacterAddress, getFakeCharacterAddress } from 'utils/addressHelpers'
import { getMarketplaceContract } from 'utils/contractHelpers'

const client = createClient({
  url: 'https://api.thegraph.com/subgraphs/name/longluckytech/marketplace1',
})

export const fetchSellerFee = createAsyncThunk('marketplace/fetchSellerFee', async (_) => {
  const marketplaceContract = getMarketplaceContract()
  try {
    return (await marketplaceContract.sellerFee()).toString()
  } catch (e) {
    console.log(e)
  }
})

export const fetchBuyerFee = createAsyncThunk('marketplace/fetchBuyerFee', async (_) => {
  const marketplaceContract = getMarketplaceContract()
  try {
    return (await marketplaceContract.buyerFee()).toString()
  } catch (e) {
    console.log(e)
  }
})

export const fetchAmountCiviliansListing = createAsyncThunk('marketplace/fetchAmountCiviliansListing', async (_) => {
  const marketplaceContract = getMarketplaceContract()
  try {
    return parseInt((await marketplaceContract.getLengthOfAllListing()).toString())
  } catch (e) {
    console.log(e)
  }
})

const fetchingMarketDataByPage = async (counterPage, limit) => {
  try {
    const characterAddressLowercase = getCharacterAddress().toLowerCase()

    const ListingsQuery = gql`
      query Listings($characterAddress: String!, $skip: Int!, $first: Int!) {
        nfts(
          first: $first
          skip: $skip
          where: { address: $characterAddress }
          orderBy: listingTime
          orderDirection: desc
          isTradable: true
        ) {
          id
          tokenId
          address
          currentAskPrice
          currentSeller
        }
      }
    `

    const {
      data: { nfts },
      error,
    } = await client
      .query(ListingsQuery, { characterAddress: characterAddressLowercase, skip: counterPage * limit, first: limit })
      .toPromise()

    if (error) throw new Error(error.message)

    const listingCharacters = nfts.map((civilian) => ({
      _id: `${utils.getAddress(civilian.address)}_${civilian.tokenId}`,
      seller: civilian.currentSeller,
      paymentToken: '0xE9D583aDF709001dfcEad8875a46F2b784b6ab71',
      listingNft: utils.getAddress(civilian.address),
      id: civilian.tokenId,
      price: civilian.currentAskPrice,
    }))

    let groupedListing: any = {
      [getCharacterAddress()]: [],
      [getFakeCharacterAddress()]: [],
    }

    groupedListing = { ...groupedListing, ...(groupBy(listingCharacters, 'listingNft') as any) }

    const charIds = map(groupedListing[getCharacterAddress()], 'id')
    const fakeCharIds = map(groupedListing[getFakeCharacterAddress()], 'id')

    return {
      charIds,
      fakeCharIds,
      groupedListing,
    }
  } catch (error) {
    console.log('error fetching market page : ', error)
  }
}

export const fetchListingCivilians = createAsyncThunk(
  'marketplace/fetchListingCivilians',
  async (_, { getState, dispatch }) => {
    try {
      dispatch(fetchingMarket(true))
      const characterAddress = getCharacterAddress()
      const fakeCharacterAddress = getFakeCharacterAddress()
      let characters = []
      let characterIds = []
      let fakeCharacterIds = []
      let groupedListingCharacters = {
        [characterAddress]: [],
        [fakeCharacterAddress]: [],
      }

      const {
        marketplace: {
          pagination: { limit, marketPage },
        },
      } = getState() as State

      const counterPage = marketPage
      const { charIds, fakeCharIds, groupedListing }: any = await fetchingMarketDataByPage(counterPage, limit)

      characterIds = [...characterIds, ...charIds]
      fakeCharacterIds = [...fakeCharacterIds, ...fakeCharIds]
      groupedListingCharacters = {
        [characterAddress]: groupedListing[characterAddress],
        [fakeCharacterAddress]: groupedListing[fakeCharacterAddress],
      }

      await Promise.all([
        _getCiviliansByIds(characterAddress, characterAbi, 'getHero', characterIds, true),
        _getCiviliansByIds(fakeCharacterAddress, fakeCharacterAbi, 'getHero', fakeCharacterIds, false),
      ]).then((values) => {
        characters = [
          ..._values(merge(keyBy(values[0], '_id'), keyBy(groupedListingCharacters[characterAddress], '_id'))),
          ..._values(merge(keyBy(values[1], '_id'), keyBy(groupedListingCharacters[fakeCharacterAddress], '_id'))),
        ]
      })

      dispatch(fetchingMarket(false))
      dispatch(updateMarketPage(counterPage))

      return characters
    } catch (e) {
      return []
    }
  },
)

const _processingTransaction = async (tx: any, message: string, error: string, showPopupTransaction) => {
  try {
    await tx.wait()

    if (showPopupTransaction) {
      showPopupTransaction(tx, {
        summary: message,
      })
    }

    return true
  } catch (e) {
    if (showPopupTransaction) {
      showPopupTransaction(tx, {
        summary: error,
      })
    }

    return false
  }
}

export const fetchListCivilians = createAsyncThunk(
  'marketplace/fetchListCivilians',
  async (params: any = {}, { dispatch }) => {
    try {
      dispatch(fetchingMarket(true))

      const response = await API({
        url: config.API.GET_CIVILIANS_MARKETPLACE,
        params,
      })
      dispatch(fetchingMarket(false))

      return response
    } catch (error) {
      console.log('err', error)
    }
  },
)

export const fetchDetailCivilian = createAsyncThunk(
  'marketplace/fetchDetailCivilian',
  async ({ tokenId, params }: any) => {
    // try {
    //   const heroPromise = API({
    //     url: `${config.API.GET_CIVILIANS_MARKETPLACE}/${params}`,
    //   })

    //   const historyPromise = API({
    //     url: `${config.API.GET_TRANSACTION_HISTORY}/${params}`,
    //   })

    //   const [heroRes, historyRes] = await Promise.all([heroPromise, historyPromise])

    //   const hero = { ...heroRes, ...gameCharacter[heroRes.heroRarity][heroRes.heroName], history: historyRes }

    //   return hero
    // } catch (error) {
    //   console.log('error', error)
    // }
    try {
      const historyPromise = API({
        url: config.API.GET_TRANSACTION_HISTORY,
        params,
      })

      const heroPromise = API({
        url: `${config.API.GET_CIVILIANS_MARKETPLACE}/${tokenId}`,
      })

      const [heroRes, historyRes] = await Promise.all([heroPromise, historyPromise])

      const hero = { ...heroRes, ...gameCharacter[heroRes.heroRarity][heroRes.heroName], history: historyRes.results }

      return hero
    } catch (error) {
      console.log('error fetch', error)
    }
  },
)
