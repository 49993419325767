import { Slider } from 'antd'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { filtersSelector } from 'state/marketplace/selectors/selector'
import styled from 'styled-components'

const marks = {
  1: '1',
  10: '10',
}

function LevelFilter({ handleChangeFilter }) {
  const [level, setLevel] = useState<[number, number]>([1, 10])

  const filters = useSelector(filtersSelector)
  useEffect(() => {
    if (isEmpty(filters)) setLevel([1, 10])
  }, [filters])
  const handleChange = (value) => {
    handleChangeFilter({
      filters: {
        ...filters,
        level: `${value?.[0]},${value?.[1]}`,
      },
    })
    setLevel(value)
  }
  return (
    <LevelFilterStyled>
      <Slider onChange={handleChange} range marks={marks} defaultValue={[1, 10]} min={1} max={10} value={level} />
    </LevelFilterStyled>
  )
}

const LevelFilterStyled = styled.div`
  .ant-slider.ant-slider-with-marks {
    &:hover .ant-slider-track {
      background: #3786ff;
    }
  }
  .ant-slider-handle {
    width: 18px;
    height: 18px;
    background: #3786ff;
    border: unset;
    margin-left: 5px;
  }

  .ant-slider-rail {
    height: 8px;
  }

  .ant-slider-track {
    height: 8px;
    background: #3786ff;
  }

  .ant-slider-dot.ant-slider-dot-active {
    background: #3786ff;
  }

  .ant-slider-dot {
    /* width: 18px;
    height: 18px;
    border: unset;
    background: #fff;
    top: -4px;
    transform: translate(0px, -50%);
    margin-left: 5px; */
    display: none;
  }

  .ant-slider-mark-text-active {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 5px;
  }

  span.ant-slider-mark-text {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 5px;
  }
`
export default LevelFilter
