import { createAsyncThunk } from '@reduxjs/toolkit'
import API from 'api'
import config from 'api/config'
// eslint-disable-next-line
import { HISTORY_ACTION } from './constants'
const { GET_TRANSACTION_HISTORY } = HISTORY_ACTION

export const fetchTransactionHistory = createAsyncThunk<any, any>(GET_TRANSACTION_HISTORY, async (params) => {
  try {
    const response = await API({
      url: config.API.GET_TRANSACTION_HISTORY,
      params,
    })
    return {
      listing: response,
    }
  } catch (error) {
    console.log('error fetch', error)
  }
})
