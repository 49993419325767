import { ethers } from 'ethers'
import tokens from 'config/constants/tokens'
import { getBusdAddress, getDptAddress } from 'utils/addressHelpers'

export const paymentTokens = [
  {
    name: 'BNB',
    address: {
      56: ethers.constants.AddressZero,
      97: ethers.constants.AddressZero,
    },
    image: '/images/coinslist/BNB.png',
  },
  {
    name: 'BUSD',
    address: tokens.busd.address,
    image: '/images/fileDiviner/frameprice.png',
  },
  {
    name: 'DPT',
    address: tokens.dpt.address,
    image: '/images/fileDiviner/frameprice.png',
  },
]

export const TAB = {
  GIFT: 0,
  LUCKY: 1,
}

export const NFT_TYPE = {
  BOX: 0,
  SHOE: 1,
}

export const BOX_TYPE = {
  GIFT: 0,
  LUCKY: 1,
}

export const SHOE_TYPE = {
  0: 'MID1',
  1: 'MID2',
  2: 'BURN1',
  3: 'BURN2',
  4: 'VIPBURN',
}

export const boxTypes = ['LUCKY_BOX', 'GIFT_BOX', 'SHOE']

export const boxes = [
  {
    name: 'Gift box',
    img: 'gift-box.png',
    type: BOX_TYPE.GIFT,
  },
  {
    name: 'Lucky box',
    img: 'box.png',
    type: BOX_TYPE.LUCKY,
  },
]

export const boxValues = {
  GIFT_BOX: {
    img: 'gift-box.png',
  },
  LUCKY_BOX: {
    img: 'box.png',
  },
}

export const getPaymentToken = (paymentToken: string) => {
  switch (paymentToken) {
    case ethers.constants.AddressZero:
      return {
        symbol: 'BNB',
        img: 'bnb.png',
      }
    case getBusdAddress():
      return {
        symbol: 'BUSD',
        img: 'busd.svg',
      }
    case getDptAddress():
      return {
        symbol: 'DPT',
        img: 'dpt.svg',
      }
    default:
      return {
        symbol: 'DPT',
        img: 'dpt.svg',
      }
  }
}
