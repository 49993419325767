import React, { useRef } from 'react'
import { Input, Select, Space } from 'antd'
import styled from 'styled-components'
import { SORT_OPTION } from 'constants/index'
import { useSelector } from 'react-redux'
import { filtersSelector, sortSelector } from 'state/marketplace/selectors/selector'
import { getBusdAddress, getDptAddress } from 'utils/addressHelpers'

export const CURRENCY_OPTION = {
  ALL: null,
  DPT: getDptAddress(),
  BUSD: getBusdAddress(),
  BNB: '0x0000000000000000000000000000000000000000',
}

const { LOWEST_PRICE, NEWEST } = SORT_OPTION
function SearchFilter({ handleChangeFilter }) {
  const selectContainer = useRef()
  const sorts = useSelector(sortSelector)
  const filters = useSelector(filtersSelector)

  const { Search } = Input
  const { Option } = Select
  const sortOption = {
    [NEWEST]: {
      updatedAt: -1,
    },
    [LOWEST_PRICE]: {
      price: 1,
    },
  }
  const handleChangeSort = (value) => {
    const sort = sortOption[value]
    handleChangeFilter({
      sorts: {
        ...sort,
      },
    })
  }
  const handleChangeCurrency = (value) => {
    handleChangeFilter({
      filters: {
        ...filters,
        paymentToken: value,
      },
    })
  }
  return (
    <SearchFilterStyled ref={selectContainer}>
      {/* <div className="search__filter">
        <Search placeholder="search" allowClear enterButton="Search" size="large" />
      </div> */}
      <div className="search__filter">
        <div className="search__filter--sort">
          <Select
            defaultValue="Newest"
            dropdownClassName="search__filter__dropdown"
            getPopupContainer={() => selectContainer.current}
            onChange={handleChangeSort}
          >
            <Option value={SORT_OPTION.NEWEST}>Newest</Option>
            <Option value={SORT_OPTION.LOWEST_PRICE}>Lowest</Option>
          </Select>
        </div>
      </div>

      <div className="search__filter bnb">
        <div className="search__filter--currency">
          <Select
            defaultValue="ALL"
            dropdownClassName="search__filter__dropdown"
            getPopupContainer={() => selectContainer.current}
            onChange={handleChangeCurrency}
          >
            <Option value={CURRENCY_OPTION.ALL}>All</Option>
            <Option value={CURRENCY_OPTION.BNB}>BNB</Option>
            <Option value={CURRENCY_OPTION.DPT}>DPT</Option>
            <Option value={CURRENCY_OPTION.BUSD}>BUSD</Option>
          </Select>
        </div>
      </div>
    </SearchFilterStyled>
  )
}

const SearchFilterStyled = styled.div`
  .search__filter {
    padding-bottom: 30px;
  }

  .search__filter__dropdown {
    z-index: 1;
  }

  .search__filter.bnb {
    .ant-select-selector {
      cursor: initial;
    }
  }

  .search__filter:nth-child(3) {
    padding-bottom: 0px;
  }

  .ant-input-group {
    display: grid;
    grid-template-columns: 1fr 83px;
    grid-gap: 8px;
  }

  .ant-input-affix-wrapper {
    background: #011446;
    border-radius: 76px !important;
    border: unset;
    width: 100%;
    display: flex;
    align-items: center;
    height: 46px;

    &::before {
      content: url('./images/home/search.svg');
      width: 20px;
      height: 20px;
      visibility: unset;
      padding-right: 30px;
      margin-top: -5px;
    }

    .ant-input {
      background: #011446;
      color: #93beff;
      &::placeholder {
        color: #93beff;
      }
    }
  }

  .ant-input-group-addon {
    border-radius: 76px !important;
    background-color: transparent;

    button {
      background: #55b3f8;
      border-radius: 76px !important;
      border: unset;
      height: 46px;
    }
  }

  .ant-select-selector {
    background: #3263af !important;
    color: #fff;
    display: flex;
    align-items: center;
    height: 46px !important;
    border-radius: 23px !important;
    border: unset !important;
    font-size: 16px;
    font-weight: 400;
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    width: 100%;
  }

  .ant-select-arrow {
    color: #fff;
  }

  .ant-select-selection-item {
    &::before {
      color: #94bfff;
    }
  }

  .search__filter--sort {
    .ant-select-selection-item {
      &::before {
        content: 'Sort by: ';
      }
    }
  }

  .search__filter--currency {
    cursor: pointer;

    .ant-select-selection-item {
      &::before {
        content: 'Currency: ';
      }
    }
  }
`
export default SearchFilter
