import React from 'react'
import styled from 'styled-components'
import History from './components/History'

function PageHistory(props) {
  return (
    <div className="marketPage">
      <PageHistoryStyled>
        <History />
      </PageHistoryStyled>
    </div>
  )
}

const PageHistoryStyled = styled.div``
export default PageHistory
