import React from 'react'
import styled from 'styled-components'

function CivilianModal({
  lv = '1',
  star = 5,
  img = 'images/marketv2/jobs/200x200/police.png',
  index = null,
  animationDL = null,
}) {
  return (
    <CivilianModalStyled className="civilian-style" style={{ animationDelay: `${animationDL + index}s` }}>
      <div className="civilian__scale" style={{ animationDelay: `${animationDL + index}s` }}>
        <div className="civilian" style={{ animationDelay: `${animationDL + index}s` }}>
          <div className="civilian__lv-number">
            <img src="./images/modal-market/bg-lv.png" alt="bg-lv.png" />
            <span>{lv}</span>
          </div>
          <span className="civilian__lv">Lv.{lv}</span>
          <div className="civilian__img">
            <img src={img} alt="civilian.png" />
          </div>
          <div className="civilian__star">
            {[...Array(star)].map(() => (
              <img src="/images/market/star.svg" alt="star" />
            ))}
          </div>
        </div>
      </div>
    </CivilianModalStyled>
  )
}

const CivilianModalStyled = styled.div`
  .civilian {
    position: relative;
    background: url('./images/modal-market/bg-civilian.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 135px;
    height: 135px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;

    .civilian__lv-number {
      position: absolute;
      left: 3px;
      top: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        position: absolute;
        font-weight: 700;
      }
    }

    .civilian__lv {
      text-shadow: 0px 0px 2px #000000;
      position: absolute;
      right: 12px;
      top: 12px;
    }

    .civilian__star {
      position: absolute;
      bottom: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 50%;
      transform: translate(-50%, 0%);

      img {
        width: 16px;
        margin: 0px 1px;
      }
    }

    .civilian__img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 122px;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
`
export default CivilianModal
